<template>
  <div>
    <div class="dob_label mb-4" :class="{ 'red--text': error_message }">
      {{ input.label }}
    </div>
    <v-row>
      <v-col>
        <v-select
          v-model="date.day"
          :items="days"
          label="Day"
          @change="updateDate"
          outlined
        ></v-select>
      </v-col>
      <v-col>
        <v-select
          v-model="date.month"
          :items="months"
          label="Month"
          item-text="label"
          item-value="value"
          @change="updateDate"
          outlined
        ></v-select>
      </v-col>
      <v-col>
        <v-select
          v-model="date.year"
          :items="years"
          label="Year"
          @change="updateDate"
          :append-outer-icon="input.helper ? 'mdi-help-circle-outline' : ''"
          @click:append-outer="
            input.helper
              ? $store.commit('setSideDrawerRequest', input.helper)
              : false
          "
          outlined
        ></v-select>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    input: {
      type: Object,
      required: true,
    },
    value: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      date: {
        day: null,
        month: null,
        year: null,
      },
      days: Array.from({ length: 31 }, (_, k) => k + 1),
      months: [
        { label: "January", value: "01" },
        { label: "February", value: "02" },
        { label: "March", value: "03" },
        { label: "April", value: "04" },
        { label: "May", value: "05" },
        { label: "June", value: "06" },
        { label: "July", value: "07" },
        { label: "August", value: "08" },
        { label: "September", value: "09" },
        { label: "October", value: "10" },
        { label: "November", value: "11" },
        { label: "December", value: "12" },
      ],
      years: [],
    };
  },
  mounted() {
    this.preset();
    this.calculateYears();
  },
  computed: {
    error_message() {
      return this.$store.getters.hasErrors[this.input.name] || null;
    },
    formattedDate() {
      if (this.date.year && this.date.month && this.date.day) {
        const { year, month, day } = this.date;
        const adjustedDate = moment(
          `${year}-${month}-${day}`,
          "YYYY-MM-DD"
        ).startOf("day");
        return adjustedDate.format("YYYY-MM-DD");
      }
      return null;
    },
  },
  watch: {
    formattedDate(date) {
      this.$emit("update:value", date);
    },
  },
  methods: {
    preset() {
      if (!this.value || this.value.length === 0) return;
      const [year, month, day] = this.value.split("-");
      this.date.year = parseInt(year);
      this.date.month = month;
      this.date.day = parseInt(day);
    },
    calculateYears() {
      const yearsFrom = this.input.years_from || -70;
      const yearsTo = this.input.years_until || 0;
      const startYear = moment().year() + yearsFrom;
      const endYear = moment().year() + yearsTo;
      this.years = [];
      for (let i = endYear; i >= startYear; i--) {
        this.years.push(i);
      }
    },
    updateDate() {
      if (!this.date.year || !this.date.month || !this.date.day) return;

      const { year, month, day } = this.date;
      const adjustedDate = moment(
        `${year}-${month}-${day}`,
        "YYYY-MM-DD"
      ).startOf("day");
      const formattedDate = adjustedDate.format("YYYY-MM-DD");

      this.$emit("update:value", formattedDate);
    },
  },
};
</script>

<style scoped>
.dob_label {
  font-size: 0.925rem;
  margin-bottom: 0;
  color: rgba(0, 0, 0, 0.6);
}
</style>
