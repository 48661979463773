import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: false,
        themes: {
          light: {
            primary: colors.indigo.lighten2,
            secondary: colors.grey.darken1,
            accent: colors.shades.black,
            error: colors.red.accent3,
            // background: colors.grey.lighten4, // Not automatically applied
          },
          dark: {
            primary: colors.blue.lighten3, 
            background: colors.blue.lighten1, // If not using lighten/darken, use base to return hex
          },
        },
      },
});
