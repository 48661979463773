<template>
  <v-container fluid v-if="input.type === 'checkbox'" class="pa-0 ma-0">
    <div class="checkbox_label">
      {{ input.label }}

      <v-icon
        v-if="input.helper"
        @click="$store.commit('setSideDrawerRequest', input.helper)"
        >mdi-help-circle-outline</v-icon
      >
    </div>

    <div class="checkbox-group">
      <span
        v-for="option in input.options"
        :key="option.id"
        class="checkbox-item"
      >
        <input
          type="checkbox"
          :id="option.id"
          :value="option"
          :checked="isSelected(option)"
          @change="updateSelectedOptions(option)"
          class="checkbox-input"
        />
        <label
          :for="option.id"
          :style="{
            backgroundColor: option.colour,
            opacity: isSelected(option) ? 1 : 0.5,
          }"
          :class="{ 'checkbox-selected': isSelected(option) }"
        >
          <v-icon small v-show="isSelected(option)">mdi-check-circle</v-icon>
          {{ option.label }}
        </label>
      </span>
    </div>
  </v-container>
</template>

<script>
export default {
  props: ["input", "values"],

  data() {
    return {
      selected_options: this.values || [],
    };
  },
  methods: {
    isSelected(option) {
      return this.selected_options.some(
        (selected) => selected.id === option.id
      );
    },
    updateSelectedOptions(option) {
      const index = this.selected_options.findIndex(
        (selected) => selected.id === option.id
      );
      if (index > -1) {
        this.selected_options.splice(index, 1);
      } else {
        this.selected_options.push(option);
      }
      this.$emit("update:values", this.selected_options);
    },
  },
};
</script>

<style scoped>
.checkbox_label {
  font-size: 0.925rem;
  margin-bottom: 12px;
  color: rgba(0, 0, 0, 0.6);
}

.checkbox-group {
  display: flex;
  flex-wrap: wrap;
}

.checkbox-item {
  margin-right: 10px;
  margin-bottom: 10px;
}

label {
  display: inline-flex;
  align-items: center;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  transition: opacity 0.3s;
  border: 1px solid #787070;
}

.v-icon {
  margin-right: 5px;
  color: green;
}

.checkbox-selected {
  font-weight: bold;
}

.checkbox-input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
</style>
