<template>
  <v-select
    v-if="input.type == 'select'"
    v-model="select_input"
    :items="input.options"
    :label="input.label"
    :item-text="input.display"
    :item-value="input.value ? input.value : 'id'"
    :disabled="input.disabled || input.options.length == 0"
    :error-messages="error_message"
    :loading="isLoading"
    @change="changeHandler"
    :append-outer-icon="input.helper ? 'mdi-help-circle-outline' : null"
    @click:append-outer="
      input.helper ? $store.commit('setSideDrawerRequest', input.helper) : false
    "
    outlined
  ></v-select>
</template>

<script>
export default {
  props: ["input", "value"],
  data() {
    return {
      selectedObject: null,
      isLoading: false,
    };
  },
  computed: {
    select_input: {
      get() {
        return this.value;
      },
      set(value) {
        this.$store.dispatch("setFormValue", {
          fieldName: this.input.name,
          value: value,
        });
      },
    },
    error_message() {
      return this.$store.getters.hasErrors[this.input.name]
        ? this.$store.getters.hasErrors[this.input.name]
        : null;
    },
  },
  methods: {
    changeHandler(value) {
      this.select_input = value;
      this.selectedObject = this.input.options.find(
        (option) => option[this.input.value ? this.input.value : "id"] === value
      );
      this.getRelated(this.selectedObject);
    },
    getRelated(option) {
      if (
        typeof this.input.onchange == "undefined" ||
        typeof option.url == "undefined"
      )
        return;

      this.isLoading = true;
      this.$store.dispatch("setFormValue", {
        fieldName: this.input.onchange,
        value: null,
      });

      this.$store
        .dispatch("apiRequest", {
          method: "GET",
          endpoint: option.url,
        })
        .then(() => {
          const foundOptions = this.$store.getters.apiResponse.data.data;
          this.$store.dispatch("updateFormFieldOptions", {
            fieldName: this.input.onchange,
            options: foundOptions,
          });

          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
<style>
.v-text-field__details {
  margin-bottom: 0 !important;
}
</style>
