<template>
  <v-menu offset-y open-on-hover v-if="menu">
    <template v-slot:activator="{ attrs, on }">
      <v-icon
        v-if="size === 'small'"
        small
        v-bind="attrs"
        v-on="on"
        class="menu-icon"
        >mdi-dots-vertical</v-icon
      >
      <v-icon v-else v-bind="attrs" v-on="on" class="menu-icon"
        >mdi-dots-horizontal</v-icon
      >
    </template>

    <v-list dense>
      <v-list-item
        v-for="link in menu"
        :key="link.index"
        :to="link.action === 'redirect' ? link.endpoint : null"
        @click="handleClick(link)"
        link
      >
        <v-list-item-content>
          <v-list-item-title>{{ link.label }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: ["menu", "size"],
  methods: {
    handleClick(link) {
      if (link.action !== "redirect") {
        this.$store.dispatch("processAction", {
          endpoint: link.endpoint,
          action: link.action,
          type: link.type,
        });
      }
    },
  },
};
</script>

<style scoped>
.menu-icon {
  max-height: 40px;
}
</style>
