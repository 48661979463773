<template>
  <v-container
    :style="pageSize == 'small' ? 'max-width:600px;' : 'max-width:700px;'"
  >
    <div v-if="form">
      <v-form @submit.prevent>
        <form-input-item
          v-for="field in form.fields"
          :key="field.id"
          :field="field"
        />
        <div v-if="form.settings && form.settings.btn" class="my-3 text-center">
          <v-btn color="primary" type="button" outlined @click="submit">{{
            form.settings.btn
          }}</v-btn>
        </div>
      </v-form>

      <elements-action-btns
        v-if="form.settings"
        :actions="form.settings.links"
        btnType="plain"
      />
    </div>
    <small v-else>Found not found</small>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["form"]),
    pageSize() {
      return this.form && this.form.settings && this.form.settings.container
        ? this.form.settings.container
        : null;
    },
  },
  watch: {},
  methods: {
    submit() {
      this.$store.commit("setErrors", []);
      this.$store.dispatch("apiRequest", {
        method: this.form.settings.method,
        endpoint: this.form.settings.endpoint,
        params: this.form.values,
      });
    },
  },
};
</script>

<style></style>
