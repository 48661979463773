<template>
  <vue-editor v-model="content" :editor-toolbar="customToolbar" class="my-8" />
</template>

<script>
import { VueEditor } from "vue2-editor";

export default {
  props: ["input", "value"],
  components: { VueEditor },

  data: () => ({
    customToolbar: [
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"],
      ["blockquote"],
    ],
  }),

  computed: {
    content: {
      get() {
        return this.value ? this.value : "";
      },
      set(value) {
        this.$store.dispatch("setFormValue", {
          fieldName: this.input.name,
          value: value,
        });
      },
    },
    error_message() {
      return this.$store.getters.hasErrors[this.input.name]
        ? this.$store.getters.hasErrors[this.input.name]
        : null;
    },
  },
};
</script>

<style></style>
