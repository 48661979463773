<template>
  <div>
    <div v-if="loading" class="text-center pa-4">
      <v-progress-circular
        indeterminate
        class="mx-auto"
        :size="70"
        :width="7"
      />
    </div>
    <v-container v-if="data" style="max-width: 950px">
      <div class="d-flex page_header">
        <div>
          <div v-if="data.overline" class="overline">{{ data.overline }}</div>
          <div class="text-h1">{{ data.header }}</div>
        </div>
        <elements-action-btns :actions="data.actions" />
      </div>

      <div class="d-flex justify-space-between align-center flex-wrap py-4">
        <v-card
          flat
          v-for="(item, index) in data.items"
          :key="index"
          class="sub_card rounded"
          :style="item.status == 'active' ? 'opacity:1;' : 'opacity:0.4'"
        >
          <v-card-title>
            <div class="overline">{{ item.overline }}</div>
            <div class="text-h1">{{ item.label }}</div>
          </v-card-title>

          <v-card-text>
            <div class="mb-6 body-2" v-html="item.description"></div>
            <div><strong>Breakdown results & filter by:</strong></div>
            <ul class="mt-2">
              <li v-for="(benefit, index) in item.items" :key="index">
                <span v-html="benefit"></span>
              </li>
            </ul>
          </v-card-text>

          <v-card-title v-if="item.price" class="justify-center">
            <div class="text-h5">
              {{ item.price }} <span class="caption">per month</span>
            </div>
          </v-card-title>

          <v-card-actions v-if="item.actions" class="justify-center">
            <v-btn
              v-for="(action, index) in item.actions"
              class="text-wrap"
              :key="index"
              :color="action.colour ? action.colour : 'success'"
              :disabled="action.action == 'disabled'"
              @click="
                action.warning
                  ? openWarning(action)
                  : $store.dispatch('processAction', action)
              "
              outlined
              >{{ action.label }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
      <div
        v-if="data.description"
        class="text-body py-5"
        v-html="data.description"
      ></div>

      <!-- {{ data }} -->
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({
    data: {},
    loading: true,
  }),
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.data = {};
      this.$store
        .dispatch("apiRequest", {
          method: "GET",
          endpoint: "subscriptions",
        })
        .then(() => {
          this.data = this.$store.getters.apiResponse.data;
          document.title = this.data.header + " | Schdoo";
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.page_header {
  justify-content: center;
  flex-wrap: wrap;
  padding: 0;
}
@media (min-width: 600px) {
  .page_header {
    justify-content: space-between;
    padding-top: 20px;
  }
}
.sub_card {
  max-width: 300px;
  margin: 5px auto;
  padding: 5px;
  background-color: #f2f2f2 !important;
}
</style>
