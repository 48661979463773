<template>
  <v-navigation-drawer
    v-if="data"
    v-model="drawer"
    fixed
    temporary
    width="340"
    style="z-index: 99999"
  >
    <elements-article-preview
      v-if="data.type == 'article'"
      :endpoint="data.endpoint"
    />
    <elements-content-block
      v-else-if="data.type == 'content'"
      :data="data"
      class="px-3"
    />
    <elements-infinite-results v-else :data="data" class="pa-2" />
  </v-navigation-drawer>
</template>

<script>
export default {
  data() {
    return {
      data: null,
      drawer: this.$store.state.sideDrawerActive,
    };
  },
  watch: {
    "$store.state.sideDrawerActive": function () {
      this.drawer = this.$store.state.sideDrawerActive;
    },
    "$store.state.sideDraweRequest": function () {
      this.data = this.$store.state.sideDraweRequest;
    },
    drawer() {
      if (this.drawer == this.$store.state.sideDrawerActive) return;
      this.$store.commit("setSideDrawerActive", this.drawer);
    },
  },
  methods: {},
};
</script>

<style></style>
