<template>
  <div v-if="banner">
    <v-card
      flat
      shaped
      :color="banner.colour ? banner.colour : 'blue lighten-5'"
    >
      <v-card-title v-if="banner.header" class="text-h2"
        >{{ banner.header }}
      </v-card-title>
      <v-card-subtitle class="text-h5" v-if="banner.subtitle"
        ><span v-html="banner.subtitle"></span
      ></v-card-subtitle>
      <v-card-text v-if="banner.text">
        <span class="body-2" v-html="banner.text"></span>
        <div v-for="(item, index) in banner.list" :key="index" :item="item">
          {{ item }}
        </div>
      </v-card-text>
      <v-card-actions v-if="banner.actions">
        <elements-action-btns :actions="banner.actions" />
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["banner"],
};
</script>

<style></style>
