<template>
  <div class="author-container d-flex align-center">
    <div class="avatar-container">
      <v-avatar
        v-if="representing"
        size="30"
        color="grey"
        class="representing-avatar"
        :class="{ hovered: hoverRepresenting }"
        @mouseover="hoverRepresenting = true"
        @mouseleave="hoverRepresenting = false"
        @click="$store.dispatch('redirect', representing.url)"
      >
        <v-img
          v-if="representing.avatar"
          :src="representing.avatar.src_small"
          :alt="representing.avatar.name"
        />
        <span v-else-if="representing.label" class="white--text caption">{{
          representing.label
            .split(" ")
            .map((n) => n[0])
            .join("")
        }}</span>
      </v-avatar>
      <v-avatar
        v-if="user"
        size="27"
        color="grey"
        class="user-avatar"
        :class="{ hovered: hoverUser }"
        @mouseover="hoverUser = true"
        @mouseleave="hoverUser = false"
        @click="$store.dispatch('redirect', user.url)"
      >
        <v-img
          v-if="user.avatar"
          :src="user.avatar.src_small"
          :alt="user.avatar.name"
        />
        <span v-else class="white--text caption">{{
          user.label
            .split(" ")
            .map((n) => n[0])
            .join("")
        }}</span>
      </v-avatar>
    </div>
    <div class="label-container" :style="{ marginLeft: user ? '27px' : '7px' }">
      <span
        v-if="user"
        class="user-label"
        :class="{ hovered: hoverUser }"
        @mouseover="hoverUser = true"
        @mouseleave="hoverUser = false"
        @click="$store.dispatch('redirect', user.url)"
        >{{ user.label }}</span
      >
      <span v-if="user && representing"> @ </span>
      <span
        v-if="representing"
        class="representing-label"
        :class="{ hovered: hoverRepresenting }"
        @mouseover="hoverRepresenting = true"
        @mouseleave="hoverRepresenting = false"
        @click="$store.dispatch('redirect', representing.url)"
        >{{ representing.label }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: ["user", "representing"],
  data() {
    return {
      hoverUser: false,
      hoverRepresenting: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.author-container {
  margin: 5px 0;
  .v-avatar {
    cursor: pointer;
  }
  .avatar-container {
    position: relative;
    display: flex;
    align-items: center;
  }

  .user-avatar {
    position: absolute;
    bottom: -2px;
    right: -23px;
    z-index: 2;
    outline: 2px solid #fff;
  }

  .label-container {
    color: #6e6d6d;
    font-size: 0.95rem;
    font-weight: 400;
    line-height: 1rem;
  }

  .user-label,
  .representing-label {
    cursor: pointer;
  }

  .user-label.hovered,
  .representing-label.hovered,
  .representing-avatar.hovered,
  .user-avatar.hovered {
    color: #000;
    font-weight: 500;
  }

  .representing-avatar.hovered,
  .user-avatar.hovered {
    outline: 2px solid #077147;
  }
}
</style>
