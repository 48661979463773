<template>
  <v-list v-if="items && items.length > 0" class="ma-0 pa-0">
    <v-list-item
      v-for="(article, index) in items"
      :key="index"
      @click="
        $store.commit('setSideDrawerRequest', {
          endpoint: article.url,
          type: 'article',
        })
      "
    >
      <v-list-item-content>
        <v-list-item-title v-if="article.title" class="text-wrap">
          {{ article.title }}
        </v-list-item-title>
        <v-list-item-subtitle v-if="article.headline" class="text-wrap">{{
          article.headline
        }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  props: ["id"],
  data() {
    return {
      items: [],
    };
  },
  watch: {
    id: {
      handler: function () {
        this.getDetails();
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    getDetails() {
      if (!this.id) return;
      this.$store
        .dispatch("apiRequest", {
          method: "GET",
          endpoint: "articles/" + this.id + "/links",
        })
        .then(() => {
          this.items = this.$store.getters.apiResponse.data;
        });
    },
  },
};
</script>

<style scoped>
.v-list-item {
  border-bottom: 1px solid #dedede;
}
</style>
