<template>
  <v-app-bar fixed app flat color="white">
    <v-container class="d-flex align-center py-0 px-4 my-0 justify-space">
      <v-toolbar-title
        style="cursor: pointer; min-width: 90px"
        @click="$route.path !== '/' ? $router.push('/') : null"
        >Schdoo</v-toolbar-title
      >

      <v-spacer></v-spacer>

      <v-menu v-if="activeUser.menu" offset-y open-on-hover>
        <template v-slot:activator="{ attrs, on }">
          <v-badge
            :content="activeUser.unread"
            offset-y="45"
            offset-x="25"
            :color="activeUser.unread > 0 ? 'red' : 'transparent'"
          >
            <v-avatar
              size="45"
              class="mr-3 user_menu"
              color="grey"
              v-bind="attrs"
              v-on="on"
            >
              <v-img
                v-if="activeUser.avatar"
                :src="activeUser.avatar.src_small"
                :alt="activeUser.name + 'profile'"
              />
              <span v-else class="white--text text-h5">{{ initials }}</span>
            </v-avatar>
          </v-badge>
        </template>

        <v-list dense>
          <v-list-item
            v-for="link in activeUser.menu"
            :key="link.index"
            :to="link.action == 'redirect' ? link.endpoint : null"
            @click="
              link.action == 'redirect'
                ? false
                : $store.dispatch('processAction', {
                    endpoint: link.endpoint,
                    action: link.action,
                    type: link.type,
                  })
            "
            link
          >
            <v-list-item-content>
              <v-list-item-title v-html="link.label" />
              <v-list-item-subtitle
                v-if="link.sublabel"
                v-html="link.sublabel"
              />
            </v-list-item-content>
            <v-badge
              v-if="link.badge"
              color="error"
              :content="link.badge"
              inline
            ></v-badge>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-btn
        v-if="!activeUser.menu"
        color="success"
        outlined
        :to="'/register' + currentRoute"
      >
        Sign up
      </v-btn>
      <v-btn
        v-if="!activeUser.menu"
        color="success"
        outlined
        :to="'/login' + currentRoute"
      >
        Login
      </v-btn>
    </v-container>
  </v-app-bar>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "NavigationBar",
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["activeUser"]),
    initials() {
      return !this.activeUser.name
        ? null
        : this.activeUser.name
            .split(" ")
            .map((n) => n[0])
            .join("");
    },
    currentRoute() {
      if (["/login"].includes(this.$router.currentRoute.path)) {
        return "";
      }
      return "?path=" + this.$router.currentRoute.fullPath;
    },
  },
  mounted() {
    this.getData();
    this.pollData();
  },
  methods: {
    pollData() {
      const polling = setInterval(() => {
        this.getData();
      }, 10000);
      setTimeout(() => {
        clearInterval(polling);
      }, 36000000);
    },
    getData() {
      this.$store.dispatch("apiRequest", {
        method: "GET",
        endpoint: "user",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.user_menu {
  cursor: pointer;
}
</style>
