<template>
  <v-card v-if="user" class="mb-2" outlined flat>
    <v-chip
      class="userstatus"
      v-if="user.status"
      label
      small
      :color="user.status == 'active' ? 'success' : 'warning'"
      >{{ user.status }}</v-chip
    >
    <v-card-title
      min-height="200"
      @click="$router.push(user.url)"
      class="align-center flex-nowrap"
    >
      <v-avatar size="50" class="mr-3" color="grey" v-if="user.avatar">
        <v-img :src="user.avatar.src_small" :alt="user.avatar.name" />
      </v-avatar>
      <div>
        <div class="caption">{{ user.last_active | moment("from") }}</div>
        <h4>{{ user.full_name }}</h4>
        <elements-associations-block
          v-if="user.associations"
          :associations="user.associations"
        />
      </div>
      <elements-drop-menu :menu="user.menu" size="small" />
    </v-card-title>

    <v-card-actions v-if="user.actions">
      <elements-action-btns :actions="user.actions" />
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ["user"],
};
</script>

<style scoped>
h4 {
  word-break: keep-all;
  line-height: 1.3em;
}
.v-card__title:hover {
  cursor: pointer;
}
.userstatus {
  position: absolute;
  top: 1px;
  right: 0;
}
</style>
