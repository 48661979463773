<template>
  <v-combobox
    v-model="selected"
    :label="input.label"
    :hide-no-data="!search"
    :readonly="selected.length == 5"
    :items="results"
    item-text="label"
    :search-input.sync="search"
    hide-selected
    return-object
    multiple
    small-chips
    persistent-hint
    hint="Maximum of 5 topics"
    :error-messages="error_message"
  >
    <template v-slot:no-data>
      <v-list-item>
        <span class="subheading">No results</span>
      </v-list-item>
    </template>

    <template v-slot:selection="{ attrs, item, parent, selected }">
      <v-chip
        v-if="item === Object(item)"
        v-bind="attrs"
        :input-value="selected"
        label
      >
        <span class="pr-2"> {{ item.label }} </span>
        <v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
      </v-chip>
    </template>

    <template v-slot:item="{ item }">
      <v-list-item-content>
        <v-list-item-title>{{ item.label }}</v-list-item-title>
      </v-list-item-content>
    </template>
  </v-combobox>
</template>

<script>
export default {
  props: ["input", "values"],

  data: () => ({
    results: [],
    search: null,
  }),
  watch: {
    search(value) {
      this.lookup(value);
    },
  },
  computed: {
    error_message() {
      return typeof this.$store.getters.hasErrors[this.input.name] !==
        "undefined"
        ? this.$store.getters.hasErrors[this.input.name]
        : null;
    },
    selected: {
      get() {
        return this.values;
      },
      set(value) {
        this.$store.dispatch("setFormValue", {
          fieldName: this.input.name,
          value: value,
        });
      },
    },
  },
  methods: {
    lookup(value) {
      if (value && value.length < 2) return;

      this.$store
        .dispatch("apiRequest", {
          method: "GET",
          endpoint: "topics",
          params: { term: value },
        })
        .then(() => {
          this.results = this.$store.getters.apiResponse.data.data;
        });
    },
  },
};
</script>

<style></style>
