<template>
  <div class="image-container">
    <img
      :src="hero.src_large"
      :alt="name ? name : hero.name"
      class="responsive-image"
    />
    <div class="caption text-right white--text hero_credit" v-if="hero.credit">
      {{ hero.credit }}
    </div>
    <div v-if="hero.caption" class="caption_block">
      {{ hero.caption }}
    </div>
  </div>
</template>

<script>
export default {
  props: ["hero", "name"],
};
</script>

<style lang="scss" scoped>
.hero_credit {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 2px 5px;
  background-color: rgb(0, 0, 0, 0.4);
}
.caption_block {
  position: absolute;
  left: 0;
  bottom: 5px;
  padding: 2px 10px;
  border-left: 8px solid #78c0a8;
}

.image-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.responsive-image {
  width: 100%;
  max-height: 300px;
  object-fit: cover;
}
</style>
