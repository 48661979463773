<template>
  <div class="body-text mb-3">
    <span v-if="!expanded">
      {{ truncatedText
      }}<span v-if="shouldShowReadMore"
        >... <button @click="toggleReadMore">see more</button></span
      >
    </span>
    <span v-else>
      {{ text }} <button @click="toggleReadMore">see less</button>
    </span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      expanded: false,
    };
  },
  props: {
    text: {
      type: String,
      required: true,
    },
    length: {
      type: Number,
      default: 100,
    },
  },
  computed: {
    words() {
      return this.text.split(" ");
    },
    shouldShowReadMore() {
      return this.words.length > this.length;
    },
    truncatedText() {
      return this.words.slice(0, this.length).join(" ");
    },
  },
  methods: {
    toggleReadMore() {
      this.expanded = !this.expanded;
    },
  },
};
</script>

<style scoped>
button {
  color: #6e6d6d;
  font-size: 0.87rem;
  font-weight: 400;
  line-height: 1rem;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}
</style>
