<template>
  <div class="py-5">
    <v-row class="filter_container" v-if="data.filters">
      <v-col v-for="(filter, index) in data.filters" :key="index">
        <v-select
          v-if="filter.type == 'select'"
          v-model="options[filter.name]"
          :items="filter.options"
          :label="filter.label"
          :item-text="filter.display"
          :item-value="filter.value ? filter.value : 'id'"
          :disabled="filter.options.length == 0"
          @input="getDetails()"
          dense
          outlined
          hide-details
          clearable
        >
          <template v-slot:item="{ item }">
            <v-list-item-content>
              <v-list-item-title>
                <v-icon v-if="item.disabled" small class="mr-2"
                  >mdi-lock</v-icon
                >
                {{ item[filter.display] }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
        </v-select>
      </v-col>

      <v-spacer></v-spacer>
      <v-col v-if="data.helper">
        <v-btn
          @click="$store.commit('setSideDrawerRequest', data.helper.endpoint)"
          small
          plain
          >{{ data.helper.label }}</v-btn
        >
      </v-col>
      <v-col v-if="data.actions" class="filter_actions">
        <elements-action-btns :actions="data.actions" position="end" />
      </v-col>
    </v-row>

    <div v-if="loading" class="text-center">
      <v-progress-circular
        indeterminate
        class="mx-auto"
        :size="70"
        :width="7"
      />
      <div class="text-body-2 pa-4">Loading results</div>
    </div>
    <div v-else-if="data.results" class="results_container">
      <div class="d-flex justify-space-between flex-wrap">
        <div>
          <div v-if="data.header" class="text-h5 mr-4">{{ data.header }}</div>

          <div v-if="data.subheader" class="text-overline">
            {{ data.subheader }}
          </div>
        </div>
        <div class="caption">{{ data.results.represents }}</div>
      </div>

      <div
        v-for="(result, index) in data.results.options"
        :key="index"
        class="result_block"
      >
        <div class="d-flex justify-space-between fadeIn">
          <div class="label">{{ result.label }}</div>
          <div class="caption">{{ result.votes }} votes</div>
        </div>

        <div class="d-flex align-center">
          <v-avatar tile size="40" color="grey" v-if="result.image">
            <img :src="result.image.src_small" :alt="result.image.name" />
          </v-avatar>

          <div class="result" :class="assignColours(result.percentage)">
            <div
              class="votes grow"
              :style="'width:' + result.percentage + '%'"
            ></div>
            <div class="label fadeIn pl-2">{{ result.percentage }}%</div>
          </div>
        </div>

        <div v-if="result.breakdown.length" class="breakdown">
          <div :style="'width:' + (result.percentage - 1) + '%'" class="d-flex">
            <div
              v-for="(item, index) in result.breakdown"
              :key="index"
              class="result grow"
              :style="[
                colourIndex(item),
                { width: item.option_percentage + '%' },
              ]"
            >
              <div class="votes">
                <div
                  class="label fadeIn pl-3"
                  v-if="item.option_percentage > 10"
                >
                  {{ item.option_percentage }}%
                </div>
              </div>
            </div>
          </div>

          <div class="d-flex flex-wrap my-2">
            <div
              v-for="(item, index) in result.breakdown"
              :key="index"
              class="d-flex align-center list fadeIn"
            >
              <div
                class="swab d-flex justify-center align-center"
                :style="colourIndex(item)"
              >
                <!-- <div>{{ item.count }}</div> -->
                <div>
                  {{ item.label }}
                </div>
              </div>
              <div class="caption pl-2">
                <strong>{{ item.attribute_percentage }}%</strong> of
                <strong>{{ item.label }}</strong> votes
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="data.notice" class="text-center mb-6 py-3">
      <div class="text-h3">{{ data.notice }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["route"],
  data() {
    return {
      loading: false,
      data: [],
      options: {},
    };
  },
  watch: {
    route: {
      handler: function () {
        this.results = false;
        this.getDetails();
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    assignColours(percentage) {
      if (percentage > 75) return "first";
      if (percentage > 50) return "second";
      if (percentage > 25) return "third";
      if (percentage > 15) return "fourth";
      return "fifth";
    },
    hashCode(str) {
      let hash = 0;
      for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash |= 0;
      }
      return hash;
    },
    colourIndex(item) {
      const hash = this.hashCode(item.label + item.count);
      const hue = Math.abs(hash % 360);
      return { backgroundColor: `hsl(${hue}, 40%, 90%)` };
    },
    getDetails() {
      if (!this.route) return;
      this.loading = true;

      let options = Object.fromEntries(
        Object.entries(this.options).filter(([, v]) => v != null)
      );

      this.$store
        .dispatch("apiRequest", {
          method: "GET",
          endpoint: this.route,
          params: options,
        })
        .then(() => {
          this.data = this.$store.getters.apiResponse.data;
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.filter_container {
  margin: 4px;
  padding: 4px;
  .col {
    flex-wrap: wrap;
    min-width: 170px;
    max-width: 200px;
    margin: 0;
    padding: 7px;
  }
  .filter_actions {
    .v-card__actions {
      padding: 0;
    }
  }
}
@media (max-width: 600px) {
  .filter_container {
    margin: 0;
    padding: 0;
    .col {
      min-width: 50%;
    }
  }
}
.results_container {
  display: block;
  position: relative;
  width: 100%;
  max-width: 700px;
  margin: 50px auto;

  .result_block {
    margin: 20px 0;
    .v-avatar {
      border-radius: 5px !important;
      margin-right: 10px;
    }
    .label {
      position: relative;
      font-weight: 400;
      font-size: 18px;
    }
  }

  .result {
    display: block;
    position: relative;
    width: 100%;
    height: 35px;
    margin: 5px auto;
    background: #cbcacac6;
    border-radius: 5px;
    .votes {
      position: absolute;
      left: 0;
      height: 100%;
      border-radius: 5px 0px 0px 5px;
    }
    .label {
      font-weight: bold;
      position: relative;
      font-size: 18px;
      line-height: 35px;
    }
  }
  .breakdown {
    padding-bottom: 20px;
    margin: 5px 0 30px 0;
    text-align: left;
    border-bottom: 1px solid #b9b9b9;
    .result {
      display: block;
      position: relative;
      width: 100%;
      height: 30px;
      background: none;
      margin: 0 2px;
      border-radius: 15px;
      &:first-child {
        border-left: 2px solid #b9b9b9;
        border-radius: 0 15px 15px 0;
      }
      &:last-child {
        border-radius: 15px 0 0 15px;
        border-right: 2px solid #b9b9b9;
      }
      &:only-child {
        border-radius: 0;
      }
    }
    .votes {
      display: block;
      position: relative;
      height: 100%;
      width: 100%;
    }
    .label {
      font-weight: 600;
      font-size: 15px;
      line-height: 30px;
    }
  }
  .list {
    margin: 5px 15px 10px 5px;
    .swab {
      display: block;
      position: relative;
      padding: 5px 10px;
      min-width: 25px;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      border-radius: 15px;
    }
  }

  .fadeIn {
    -webkit-animation: fadeIn 1.5s 2s forwards;
    -moz-animation: fadeIn 1.5s 2s forwards;
    animation: fadeIn 1.5s 2s forwards;
    opacity: 0;
  }
  .pushout {
    -webkit-animation: pushout 2.2s 1s forwards;
    -moz-animation: pushout 2.2s 1s forwards;
    animation: pushout 2.2s 1s forwards;
  }
  .grow {
    -webkit-animation: grow-bar 3s 0s forwards;
    -moz-animation: grow-bar 3s 0s forwards;
    animation: grow-bar 3s 0s forwards;
    transform-origin: 0% 100%;
  }

  @keyframes grow-bar {
    0% {
      transform: scaleX(0);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: scaleX(1);
      opacity: 1;
    }
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes pushout {
    100% {
      left: 0;
    }
  }

  .first {
    .swab,
    .votes {
      background: #7a918d;
    }
    .label {
      color: #fff;
    }
  }
  .second {
    .swab,
    .votes {
      background: #93b1a7;
    }
    .label {
      color: #000;
    }
  }
  .third {
    .swab,
    .votes {
      background: #99c2a2;
    }
    .label {
      color: #000;
    }
  }
  .fourth {
    .votes {
      background: #c5edac;
    }
    .label {
      color: #000;
    }
  }
  .fifth {
    .votes {
      background: #dbfeb8;
    }
    .label {
      color: #000;
    }
  }
}
</style>
