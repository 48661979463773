<template>
  <v-container class="d-flex justify-center">
    <v-autocomplete
      v-model="autocomplete"
      class="site-search"
      placeholder="Find polls or create your own"
      prepend-inner-icon="mdi-magnify"
      :items="results"
      item-text="label"
      :search-input.sync="search"
      clearable
      return-object
      outlined
    >
      <!-- NO DATA -->
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>No matching results</v-list-item-title>
            <v-list-item-subtitle v-if="search && search.length > 5">
              Why not create a poll for <strong>{{ search }}</strong
              >?</v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item>
      </template>

      <!-- List -->
      <template v-slot:item="{ item }">
        <v-list-item :to="item.url">
          <v-list-item-content>
            <v-list-item-title v-html="item.label" />

            <v-chip
              v-if="
                item.status &&
                !['live', 'active', 'closed'].includes(item.status)
              "
              label
              color="error"
              small
            >
              <v-icon v-if="item.is_hidden" class="mr-1" small>
                mdi-eye-off-outline
              </v-icon>
              {{ item.status }}</v-chip
            >
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-autocomplete>
  </v-container>
</template>

<script>
import debounce from "lodash.debounce";

export default {
  data() {
    return {
      results: [],
      items: [],
      search: null,
      autocomplete: null,
      loading: false,
    };
  },
  watch: {
    search(value) {
      if (value && value.length < 2) return;
      this.debouncedCallback(value);
    },
  },
  created() {
    this.debouncedCallback = debounce((value) => {
      this.lookup(value);
    }, 500);
  },

  methods: {
    lookup(value) {
      this.$store
        .dispatch("apiRequest", {
          method: "GET",
          endpoint: "polls",
          params: { question: value },
        })
        .then(() => {
          this.results = this.$store.getters.apiResponse.data.data;
        })
        .catch((error) => {
          console.error("API request failed:", error);
          this.results = [];
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.site-search {
  max-width: 450px;
  border-radius: 50px;
}
</style>
