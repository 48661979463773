<template>
  <div>
    <br />
    <v-divider></v-divider>
    <br />
    <v-tabs v-if="items && items.length > 0">
      <v-tab v-for="(headers, index) in items" :key="index">{{
        headers.label
      }}</v-tab>
      <v-tab-item v-for="(items, index) in items" :key="index">
        <masonry
          class="mt-3"
          :gutter="{ default: '30px', 700: '15px' }"
          :cols="{ default: 3, 1300: 2, 800: 2, 500: 1 }"
        >
          <cards-select-template
            v-for="(item, index) in items.data"
            :key="index"
            :item="item"
          />
        </masonry>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
export default {
  props: ["item", "id"],
  data() {
    return {
      items: [],
    };
  },
  watch: {
    id: {
      handler: function () {
        this.getDetails();
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    getDetails() {
      if (!this.id) return;
      this.$store
        .dispatch("apiRequest", {
          method: "GET",
          endpoint: this.item + "/" + this.id + "/related",
        })
        .then(() => {
          this.items = this.$store.getters.apiResponse.data;
        });
    },
  },
};
</script>

<style></style>
