<template>
  <div v-if="data" class="feedback-group">
    <div class="caption">{{ data.label }}</div>
    <elements-action-btns :actions="data.actions" />
  </div>
</template>

<script>
export default {
  props: ["data"],
};
</script>

<style lang="scss" scoped>
.feedback-group {
  max-width: 160px;
  text-align: center;
  margin: 20px auto;
}
</style>
