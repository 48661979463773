<template>
  <v-card flat class="mb-4">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title v-if="region.region" class="text-h5 text-wrap">
          {{ region.region }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item
      v-for="(item, index) in region.authorities"
      :key="index"
      :item="item"
      dense
    >
      <v-list-item-content class="ma-0 pa-0 authority_container">
        <div class="d-flex align-center justify-space-between">
          <div>{{ item.school_count }} {{ item.authority }}</div>

          <elements-action-btns :actions="item.actions" btnType="plain" />
        </div>
      </v-list-item-content>
    </v-list-item>

    <v-card-actions v-if="region.actions" class="pt-0">
      <elements-action-btns :actions="region.actions" />
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ["region"],
};
</script>

<style lang="scss">
.authority_container {
  .v-card__actions {
    padding: 0;
  }
}
</style>
