var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app-bar',{attrs:{"fixed":"","app":"","flat":"","color":"white"}},[_c('v-container',{staticClass:"d-flex align-center py-0 px-4 my-0 justify-space"},[_c('v-toolbar-title',{staticStyle:{"cursor":"pointer","min-width":"90px"},on:{"click":function($event){_vm.$route.path !== '/' ? _vm.$router.push('/') : null}}},[_vm._v("Schdoo")]),_c('v-spacer'),(_vm.activeUser.menu)?_c('v-menu',{attrs:{"offset-y":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function({ attrs, on }){return [_c('v-badge',{attrs:{"content":_vm.activeUser.unread,"offset-y":"45","offset-x":"25","color":_vm.activeUser.unread > 0 ? 'red' : 'transparent'}},[_c('v-avatar',_vm._g(_vm._b({staticClass:"mr-3 user_menu",attrs:{"size":"45","color":"grey"}},'v-avatar',attrs,false),on),[(_vm.activeUser.avatar)?_c('v-img',{attrs:{"src":_vm.activeUser.avatar.src_small,"alt":_vm.activeUser.name + 'profile'}}):_c('span',{staticClass:"white--text text-h5"},[_vm._v(_vm._s(_vm.initials))])],1)],1)]}}],null,false,735026415)},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.activeUser.menu),function(link){return _c('v-list-item',{key:link.index,attrs:{"to":link.action == 'redirect' ? link.endpoint : null,"link":""},on:{"click":function($event){link.action == 'redirect'
              ? false
              : _vm.$store.dispatch('processAction', {
                  endpoint: link.endpoint,
                  action: link.action,
                  type: link.type,
                })}}},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(link.label)}}),(link.sublabel)?_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(link.sublabel)}}):_vm._e()],1),(link.badge)?_c('v-badge',{attrs:{"color":"error","content":link.badge,"inline":""}}):_vm._e()],1)}),1)],1):_vm._e(),(!_vm.activeUser.menu)?_c('v-btn',{attrs:{"color":"success","outlined":"","to":'/register' + _vm.currentRoute}},[_vm._v(" Sign up ")]):_vm._e(),(!_vm.activeUser.menu)?_c('v-btn',{attrs:{"color":"success","outlined":"","to":'/login' + _vm.currentRoute}},[_vm._v(" Login ")]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }