<template>
  <div class="mt-4" v-show="input.hidden !== true">
    <div
      v-if="input.type == 'h1'"
      v-html="input.label"
      class="my-8 text-h1"
    ></div>
    <h2 v-if="input.type == 'h2'" v-html="input.label" class="my-3 mt-3"></h2>
    <span
      v-if="input.type == 'h3'"
      v-html="input.label"
      class="text-h3 my-3 pt-6"
    ></span>
    <p v-if="input.type == 'p'" v-html="input.label" class="my-3"></p>
    <p v-if="input.type == 'link'" class="my-6">
      <a
        v-if="input.helper"
        @click="$store.commit('setSideDrawerRequest', input.helper)"
        >{{ input.label }}</a
      >
      <a v-else :href="input.value">{{ input.label }}</a>
    </p>

    <div
      v-if="input.type == 'notification'"
      class="text-center my-2 text-body-2"
    >
      {{ input.label }}
    </div>

    <div v-if="input.type == 'labelvalue'" class="my-3">
      <span class="caption grey--text"> {{ input.label }}</span>
      <br />
      <span class="text-body2">{{ input.value }}</span>
    </div>
    <elements-action-btns
      v-if="input.type == 'actions'"
      :actions="input.actions"
    />

    <!-- TEXT INPUT -->
    <v-text-field
      v-if="text_type.includes(input.type)"
      :type="input.type"
      v-model="set_value"
      :label="input.label"
      :error-messages="error_message"
      hide-details="auto"
      hide-spin-buttons
      :disabled="input.disabled"
      :append-outer-icon="input.helper ? 'mdi-help-circle-outline' : null"
      @click:append-outer="
        input.helper
          ? $store.commit('setSideDrawerRequest', input.helper)
          : false
      "
      outlined
    ></v-text-field>

    <!-- topics -->
    <form-lookup-topic
      v-if="input.type == 'topics'"
      :input="input"
      :values.sync="set_value"
    />

    <form-options-editor
      v-if="input.type == 'poll_options'"
      :route="input.value"
    />

    <!-- wysiswyg -->
    <form-wysiswyg-block
      v-if="input.type == 'wysiswyg'"
      :input="input"
      :value.sync="set_value"
    />
    <!-- Text area -->
    <v-textarea
      v-if="input.type == 'textarea'"
      v-model="set_value"
      counter
      :label="input.label"
      auto-grow
      outlined
      rows="5"
      :error-messages="error_message"
      :append-outer-icon="input.helper ? 'mdi-help-circle-outline' : null"
      @click:append-outer="
        input.helper
          ? $store.commit('setSideDrawerRequest', input.helper)
          : false
      "
    ></v-textarea>

    <v-file-input
      v-if="input.type == 'media'"
      v-model="files"
      multiple
      :label="input.label"
    ></v-file-input>

    <!-- Switch -->
    <v-switch
      v-if="input.type == 'switch'"
      v-model="boolean_switch"
      inset
      dense
      :label="input.label"
      :error-messages="error_message"
    ></v-switch>

    <!-- Select -->
    <form-select-input
      v-if="input.type == 'select'"
      :input="input"
      :value.sync="set_value"
    />

    <!-- Date  -->
    <div v-if="input.type == 'date'">
      <p>{{ input.label }}</p>
      <v-row justify="center">
        <v-date-picker v-model="date" locale="gb"></v-date-picker>
      </v-row>
    </div>

    <!-- DOB  -->
    <form-dob-picker
      v-if="input.type == 'dob'"
      :input="input"
      :value.sync="set_value"
    />

    <!-- colour -->
    <div v-if="input.type == 'colour'" class="pt-3">
      <p v-if="input.label">{{ input.label }}</p>
      <v-color-picker
        v-model="select_colour"
        class="ma-0 pa-0"
        hide-inputs
        show-swatches
        hide-canvas
        hide-sliders
        width="auto"
        :swatches="swatches"
        :value.sync="set_value"
      ></v-color-picker>
    </div>

    <!-- Time -->
    <v-menu
      v-if="input.type == 'time'"
      ref="timemenu"
      v-model="timemenu"
      :close-on-content-click="false"
      :nudge-right="40"
      :return-value.sync="time_input"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="time_input"
          :label="inputLabel"
          prepend-icon="mdi-clock-time-four-outline"
          readonly
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>

      <v-time-picker
        v-if="timemenu"
        v-model="time_input"
        :allowed-minutes="(m) => m % 5 === 0"
        full-width
        format="24hr"
        @click:minute="$refs.timemenu.save(time_input)"
      ></v-time-picker>
    </v-menu>

    <!-- Checkboxes -->
    <form-check-box
      v-if="input.type == 'checkbox'"
      :input="input"
      :values.sync="set_value"
    />
    <!-- Radio -->
    <form-radio-input
      v-if="input.type == 'radio'"
      :input="input"
      :value.sync="set_value"
    />

    <!-- {{ formdata }} -->
    <!-- {{ input}} -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: ["field"],
  data() {
    return {
      input: this.field,
      text_type: [
        "text",
        "password",
        "phone",
        "number",
        "tel",
        "url",
        "email",
        "date",
      ],
      swatches: [
        ["#FFCDD2", "#E1BEE7", "#D1C4E9"],
        ["#BBDEFB", "#81D4FA", "#B2DFDB"],
        ["#A5D6A7", "#C5E1A5", "#E6EE9C"],
        ["#FFAB91", "#CFD8DC", "#EEFF41"],
      ],
    };
  },
  computed: {
    ...mapGetters(["form"]),
    error_message() {
      return this.$store.getters.hasErrors[this.field.name] || null;
    },
    set_value: {
      get() {
        return this.form.values ? this.form.values[this.field.name] : "";
      },
      set(value) {
        if (this.input.rules) {
          if (this.input.rules.includes("slug")) {
            value = value.toLowerCase().replace(/[^a-zA-Z0-9]+/g, "-");
          }
        }
        this.$store.dispatch("setFormValue", {
          fieldName: this.field.name,
          value: value,
        });
      },
    },
    boolean_switch: {
      get() {
        return this.form.values ? this.form.values[this.field.name] : false;
      },
      set(value) {
        this.$store.dispatch("setFormValue", {
          fieldName: this.field.name,
          value: value,
        });
      },
    },
    select_colour: {
      get() {
        return this.form.values ? this.form.values[this.field.name] : "";
      },
      set(value) {
        this.$store.dispatch("setFormValue", {
          fieldName: this.field.name,
          value: value,
        });
      },
    },
    date: {
      get() {
        if (this.form.values) {
          return this.form.values[this.field.name];
        }
        return new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10);
      },
      set(value) {
        this.$store.dispatch("setFormValue", {
          fieldName: this.field.name,
          value: value,
        });
      },
    },
  },
};
</script>

<style lang="scss"></style>
