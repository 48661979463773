<template>
  <v-container class="fill-height">
    <v-progress-circular
      v-if="this.intervalIndex < 3"
      indeterminate
      class="mx-auto"
      :size="70"
      :width="7"
    />
    <div v-else>
      <h1>Schdoo</h1>
      <h2>Oops, this is embarrassing...</h2>
      <p>We're trying our best to get running again soon!</p>
    </div>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      checkInterval: [10000, 10000, 15000, 15000],
      intervalIndex: 0,
      intervalId: null,
      previousUrl: null,
    };
  },
  mounted() {
    const referrerPath = document.referrer
      ? new URL(document.referrer).pathname
      : null;
    console.log(document.referrer);
    this.previousUrl = referrerPath !== "/503" ? document.referrer : "/";
    this.checkStatus();
    this.loopCheck();
    document.title = "Back soon | Schdoo";
  },
  beforeUnmount() {
    this.clearIntervals();
  },
  beforeDestroy() {
    this.clearIntervals();
  },
  methods: {
    loopCheck() {
      if (this.intervalIndex < this.checkInterval.length) {
        setTimeout(() => {
          this.checkStatus();
          console.log("Checking service");
          this.loopCheck();
        }, this.checkInterval[this.intervalIndex]);
        this.intervalIndex++;
      } else {
        this.intervalId = setInterval(this.checkStatus, 30000);
      }
    },
    checkStatus() {
      this.$store
        .dispatch("apiRequest", {
          method: "GET",
          endpoint: "/health",
        })
        .then(() => {
          if (this.$store.getters.apiResponse.status === 200) {
            this.clearIntervals();
            window.location.href = this.previousUrl;
          }
        });
    },
    clearIntervals() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }
    },
  },
};
</script>
