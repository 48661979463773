var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"mb-5 generic-card",attrs:{"flat":""}},[(_vm.data.author || _vm.data.representing || _vm.data.status || _vm.data.user)?_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"d-flex align-center justify-space-between py-2 flex-nowrap"},[(_vm.data.author || _vm.data.representing)?_c('elements-author-block',{attrs:{"user":_vm.data.author,"representing":_vm.data.representing}}):_vm._e(),_c('elements-user-avatar',{attrs:{"user":_vm.data.user}}),_c('v-spacer'),_c('cards-tooltip-icons',{attrs:{"items":_vm.data.icons}}),(
          _vm.data.status &&
          ![
            'live',
            'active',
            'confirmed',
            'closed',
            'read',
            'unread',
          ].includes(_vm.data.status)
        )?_c('v-chip',{attrs:{"label":"","color":"error","small":""}},[(_vm.data.is_hidden)?_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" mdi-eye-off-outline ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.data.status))],1):_vm._e(),_c('elements-drop-menu',{attrs:{"menu":_vm.data.menu,"size":"small"}})],1)],1):_vm._e(),_c('v-card',{attrs:{"to":_vm.data.url,"flat":""}},[_c('v-card-title',{staticClass:"py-1 d-flex flex-nowrap"},[(_vm.data.avatar)?_c('v-avatar',{staticClass:"mr-2",attrs:{"size":"50"}},[(_vm.data.avatar)?_c('v-img',{attrs:{"src":_vm.data.avatar.src_large,"alt":_vm.data.title,"fluid":""}}):_vm._e()],1):_vm._e(),_c('div',[(_vm.data.overline)?_c('div',{staticClass:"text-overline",domProps:{"innerHTML":_vm._s(_vm.data.overline)}}):_vm._e(),(_vm.data.title)?_c('div',{staticClass:"text-h3",domProps:{"innerHTML":_vm._s(_vm.data.title)}}):_vm._e(),(_vm.data.label)?_c('div',{staticClass:"text-h3",domProps:{"innerHTML":_vm._s(_vm.data.label)}}):_vm._e(),(_vm.data.sublabel)?_c('div',{staticClass:"text-caption",domProps:{"innerHTML":_vm._s(_vm.data.sublabel)}}):_vm._e(),(_vm.data.subtitle)?_c('div',{staticClass:"text-overline",domProps:{"innerHTML":_vm._s(_vm.data.subtitle)}}):_vm._e(),(_vm.data.timestamp)?_c('div',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.data.timestamp,"from"))+" ")]):_vm._e()])],1),(_vm.data.groups && _vm.data.groups.length > 0)?_c('v-card-text',{staticClass:"my-2"},_vm._l((_vm.data.groups),function(group,index){return _c('v-chip',{key:index,staticClass:"mr-1",attrs:{"color":"primary","to":group.url,"small":""}},[_vm._v(" "+_vm._s(group.label)+" ")])}),1):_vm._e(),(
        _vm.data.items ||
        _vm.data.type ||
        _vm.data.options ||
        _vm.data.content ||
        _vm.data.permissions
      )?_c('v-card-text',{staticClass:"card-content"},[_c('cards-permissions-template',{attrs:{"permissions":_vm.data.permissions}}),(_vm.data.content)?_c('div',{staticClass:"text-p",domProps:{"innerHTML":_vm._s(_vm.data.content)}}):_vm._e(),_vm._l((_vm.data.items),function(item,index){return _c('div',{key:index},[_c('div',{staticClass:"py-1"},[_c('strong',[_vm._v(_vm._s(item.label)),_c('br')]),_vm._v(_vm._s(item.content)+" ")])])}),(_vm.data.options && _vm.data.options.length)?_c('div',{staticClass:"options_list"},[_vm._l((_vm.data.options.slice(0, 2)),function(option,index){return _c('v-list-item',{key:index},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(option.label)+" ")])],1)],1)}),(_vm.data.options.length === 3)?_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.data.options[2].label)+" ")])],1)],1):_vm._e(),(_vm.data.options.length > 3)?_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" + "+_vm._s(_vm.data.options.length - 2)+" more "+_vm._s(_vm.data.options.length - 2 === 1 ? "option" : "options")+" ")])],1)],1):_vm._e()],2):_vm._e(),(_vm.data.ends_at)?_c('v-list-item',[_c('v-list-item-content',[(_vm.data.ends_at)?_c('v-list-item-subtitle',{staticClass:"overline text-center"},[(_vm.data.ends_at)?_c('span',[_c('span',{staticClass:"red--text"},[_vm._v(" "+_vm._s(_vm.data.ends_at_label)+" "+_vm._s(_vm._f("moment")(_vm.data.ends_at,"from"))+" ")])]):_vm._e()]):_vm._e()],1)],1):_vm._e()],2):_vm._e(),(_vm.data.hero)?_c('v-img',{attrs:{"src":_vm.data.hero.src_large,"alt":_vm.data.title,"width":"100%","fluid":""}}):_vm._e(),_c('elements-action-btns',{attrs:{"actions":_vm.data.actions}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }