<template>
  <div class="d-flex align-center flex-wrap topics">
    <div
      class="topic"
      v-for="(topic, index) in topics"
      :key="index"
      @click="$store.dispatch('redirect', topic.url)"
    >
      <v-avatar v-if="topic.hero" size="30">
        <v-img :src="topic.hero.src_thumb" />
      </v-avatar>

      <span class="label">{{ topic.label }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: ["topics"],
};
</script>

<style lang="scss" scoped>
.topic {
  margin: 0 10px 10px 0;
  border-radius: 45px;
  border: 1px solid #dddcdc;
  .label {
    padding: 0px 15px 0 5px;
    font-size: 0.9rem;
    font-weight: 500;
  }
}
.topic:hover {
  cursor: pointer;
  background-color: #000;
  color: #fff;
}

.topics {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

@media (min-width: 600px) {
  .topics {
    justify-content: flex-start;
  }
}
</style>
