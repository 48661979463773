<template>
  <v-container class="pa-0 mb-9">
    <v-img
      :src="file.src_large"
      :alt="file.name"
      contain
      class="grey lighten-2"
      max-height="500"
    >
      <div
        v-if="file.credit"
        class="caption text-right white--text image_credit"
      >
        {{ file.credit }}
      </div>
    </v-img>

    <div class="my-3">
      <div class="body-3">{{ file.caption }}</div>
    </div>

    <div class="d-flex align-center">
      <elements-user-avatar
        :user="file.uploaded_by"
        :sublabelDate="file.uploaded"
      />
      <v-spacer></v-spacer>

      <div class="text-right" v-if="file.actions">
        <elements-action-btns :actions="file.actions" />
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  props: ["route"],
  data() {
    return {
      file: [],
    };
  },
  watch: {
    route: {
      handler: function () {
        this.file = false;
        this.getFile();
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    path() {
      return this.route
        ? this.route
        : location.pathname.split("/")[1] + "/" + this.$route.params.id;
    },
  },
  mounted() {
    this.getFile();
  },
  methods: {
    getFile() {
      this.$store
        .dispatch("apiRequest", {
          method: "GET",
          endpoint: this.path,
        })
        .then(() => {
          this.file = this.$store.getters.apiResponse.data;
        });
    },
  },
};
</script>

<style>
.image_credit {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 2px 5px;
  background-color: rgb(0, 0, 0, 0.4);
}
</style>
