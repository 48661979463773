<template>
  <div class="px-5">
    <v-avatar
      size="75"
      color="grey"
      @click="
        avatar
          ? $store.dispatch('processAction', {
              type: 'image',
              endpoint: avatar.url,
            })
          : false
      "
    >
      <v-img v-if="avatar" :src="avatar.src_small" :alt="avatar.name" />
      <span v-else class="white--text text-h5">{{ initials }}</span>
    </v-avatar>
    <div v-if="btn" class="text-center">
      <v-btn
        class="text-wrap"
        plain
        small
        @click="$store.dispatch('processAction', btn)"
        >{{ btn.label }}
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: ["label", "avatar", "btn"],
  data() {
    return {
      preview_src: false,
    };
  },
  computed: {
    initials() {
      return !this.label
        ? null
        : this.label
            .split(" ")
            .map((n) => n[0])
            .join("");
    },
  },
};
</script>

<style scoped>
.v-avatar {
  cursor: pointer;
}
</style>
