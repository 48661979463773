<template>
  <div
    v-if="associations.length > 0"
    class="d-flex align-center associations-header flex-wrap"
  >
    <div class="mr-2">{{ associations[0].role }}</div>

    <router-link :to="associations[0].url" class="mr-2">
      {{ associations[0].label }}
    </router-link>

    <small
      v-if="associations[0].status && associations[0].status !== 'confirmed'"
    >
      ({{ associations[0].status }})
    </small>

    <v-menu offset-y v-if="associations.length > 1">
      <template v-slot:activator="{ on, attrs }">
        <v-btn small text v-bind="attrs" v-on="on">
          +{{ associations.length - 1 }}
          {{ associations.length - 1 > 1 ? "others" : "other" }}
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item
          v-for="(association, index) in associations.slice(1)"
          :key="index"
          :to="association.url"
          component="router-link"
          class="associations mb-1"
        >
          <v-list-item-content>
            <v-list-item-subtitle>{{ association.role }} </v-list-item-subtitle>
            <v-list-item-title class="d-flex justify-space-between"
              >{{ association.label }}
              <v-chip
                v-if="association.status && association.status !== 'confirmed'"
                x-small
              >
                {{ association.status }}
              </v-chip></v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    associations: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped>
.associations-header {
  line-height: 15px;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.54);
  justify-content: center;
}
@media (min-width: 600px) {
  .associations-header {
    justify-content: left;
  }
}
</style>
