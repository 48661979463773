<template>
  <v-container>
    <div class="pa-5" v-if="error">
      <div class="text-h2">Unavailable</div>
      <p>{{ error }}</p>
    </div>
    <elements-banner-template :banner="entity.banner" />

    <div class="d-flex page_header">
      <div class="d-flex align-center avatar-and-label-container">
        <elements-profile-avatar
          v-if="entity.avatar || entity.avatar_btn"
          :label="entity.label"
          :avatar="entity.avatar"
          :btn="entity.avatar_btn"
        />

        <div class="entity_label">
          <div class="author-block" v-if="entity.author || entity.representing">
            <elements-author-block
              :user="entity.author"
              :representing="entity.representing"
            />
          </div>

          <div v-if="entity.overline" class="text-overline">
            {{ entity.overline }}
          </div>

          <div class="text-h1" v-if="entity.label">{{ entity.label }}</div>

          <elements-associations-block
            v-if="entity.associations"
            :associations="entity.associations"
          />

          <div>
            <elements-read-more
              v-if="entity.sublabel"
              :text="entity.sublabel"
              :length="30"
            />
          </div>

          <v-chip class="mt-2" v-if="entity.status" small color="warning">
            {{ entity.status }}
          </v-chip>
        </div>
      </div>
      <v-spacer></v-spacer>
      <elements-action-btns :actions="entity.actions" size="small" />
      <elements-drop-menu :menu="entity.menu" size="large" />
    </div>
    <elements-profile-tabs :items="entity.elements" />

    <div class="my-3">
      <elements-topics-block v-if="entity.topics" :topics="entity.topics" />
      <elements-action-btns :actions="entity.footer_actions" />
    </div>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      entity: [],
      error: false,
      defaultTitle: "Schdoo",
      defaultDescription: "Profiles on Schdoo",
      defaultImage: "https://schdoo.com/schdoo.png",
    };
  },
  computed: {
    route() {
      if (this.$route.params.id) {
        return location.pathname.split("/")[1] + "/" + this.$route.params.id;
      }
      return location.pathname.split("/")[1] + "/";
    },
  },
  mounted() {
    this.getDetails();
  },
  watch: {
    $route() {
      this.$store.commit("setLoading", true);
      this.error = false;
      this.entity = [];
      this.getDetails();
    },
  },

  methods: {
    getDetails() {
      this.$store
        .dispatch("apiRequest", {
          method: "GET",
          endpoint: this.route,
        })
        .then(() => {
          if (this.$store.getters.apiResponse.status == 401) {
            if (this.$store.getters.apiResponse.data.error) {
              this.error = this.$store.getters.apiResponse.data.error;
              return;
            }
            this.$router.push("/login?path=" + this.$route.path);
            return;
          }

          this.entity = this.$store.getters.apiResponse.data;
          this.updateMetaTags(this.entity);
        });
    },
    updateMetaTags(entity) {
      document.title = entity.label
        ? `${entity.label} | ` + this.defaultTitle
        : this.defaultTitle;

      const metaTags = [
        { property: "og:url", content: window.location.href },
        { property: "og:type", content: entity.entity },
        { property: "og:title", content: entity.label || this.defaultTitle },
        {
          property: "og:description",
          content: entity.sublabel || this.defaultDescription,
        },
        {
          property: "og:image",
          content: entity.hero ? entity.hero.src_large : this.defaultImage,
        },
        {
          name: "description",
          content: entity.sublabel || this.defaultDescription,
        },
        { property: "twitter:domain", content: this.defaultTitle },
        { property: "twitter:url", content: window.location.href },
        { name: "twitter:title", content: entity.label || this.defaultTitle },
        {
          name: "twitter:description",
          content: entity.sublabel || this.defaultDescription,
        },
        {
          name: "twitter:image",
          content: entity.hero ? entity.hero.src_large : this.defaultImage,
        },
      ];

      metaTags.forEach((tag) => {
        this.updateMetaTag(tag);
      });
    },
    updateMetaTag(tag) {
      let metaElement = document.querySelector(
        `meta[${tag.property ? "property" : "name"}="${
          tag.property || tag.name
        }"]`
      );
      if (metaElement) {
        metaElement.setAttribute("content", tag.content);
      } else {
        metaElement = document.createElement("meta");
        if (tag.property) {
          metaElement.setAttribute("property", tag.property);
        } else {
          metaElement.setAttribute("name", tag.name);
        }
        metaElement.setAttribute("content", tag.content);
        document.head.appendChild(metaElement);
      }
    },
  },
};
</script>

<style scoped>
.page_header {
  justify-content: center;
  flex-wrap: wrap;
  padding: 0;
}

.avatar-and-label-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.entity_label {
  text-align: center;
  width: 100%;
}
.author-block {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}
@media (min-width: 600px) {
  .page_header {
    justify-content: space-between;
  }
  .author-block {
    justify-content: flex-start;
  }

  .avatar-and-label-container {
    flex-direction: row;
    align-items: center;
    width: auto;
  }

  .entity_label {
    text-align: left;
    width: auto;
  }
}
</style>
