<template>
  <div>
    <v-autocomplete
      v-model="autocomplete"
      placeholder="Search for polls and articles"
      :items="results"
      item-text="label"
      :search-input.sync="search"
      clearable
      return-object
      style="max-width: 700px; margin: 0 auto"
    >
      <!-- NO DATA -->
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              No matching results for <strong>{{ search }}</strong>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>

      <!-- List -->
      <template v-slot:item="{ item }">
        <v-list-item-avatar tile v-if="item.hero">
          <v-img :src="item.hero.src_medium" :alt="item.hero.name" />
        </v-list-item-avatar>
        <v-list-item-content @click="saveItem(item)">
          <v-list-item-subtitle
            v-if="item.entity == 'poll'"
            v-html="item.author"
          />
          <v-list-item-title>
            {{ item.label }}
          </v-list-item-title>
          <v-list-item-subtitle
            v-if="item.entity == 'article'"
            v-html="item.author"
          />
        </v-list-item-content>
        <v-chip
          :color="item.entity == 'poll' ? 'success' : 'warning'"
          small
          label
          >{{ item.entity }}
        </v-chip>
      </template>
    </v-autocomplete>

    <masonry
      :gutter="{ default: '30px', 700: '15px' }"
      :cols="{ default: 4, 1300: 3, 800: 2, 500: 1 }"
    >
      <v-card v-for="(item, index) in items" :key="index" class="mb-4">
        <cards-select-template :item="item" />
        <v-card-actions>
          <v-btn color="error" small @click="deleteItem(item.link_id)"
            >Remove Link</v-btn
          >
        </v-card-actions>
      </v-card>
    </masonry>
    <div v-if="loading" class="text-center pa-4">
      <v-progress-circular indeterminate :size="70" :width="7" />
    </div>
  </div>
</template>

<script>
import debounce from "lodash.debounce";

export default {
  props: ["item", "id"],
  data() {
    return {
      results: [],
      items: [],
      search: null,
      autocomplete: null,
      loading: false,
    };
  },
  watch: {
    id: {
      handler: function () {
        this.getItems();
      },
      immediate: true,
      deep: true,
    },
    search(value) {
      if (value && value.length < 2) return;
      this.debouncedCallback(value);
    },
  },
  computed: {
    itemsRoute() {
      return this.item + "s/" + this.id + "/links";
    },
    searchRoute() {
      return this.item + "s/" + this.id + "/notlinked";
    },
  },
  created() {
    this.debouncedCallback = debounce((value) => {
      this.lookup(value);
    }, 500);
  },

  methods: {
    getItems() {
      if (!this.id) return;
      this.loading = true;
      this.$store
        .dispatch("apiRequest", {
          method: "GET",
          endpoint: this.itemsRoute,
        })
        .then(() => {
          this.items = this.$store.getters.apiResponse.data;
          this.loading = false;
        });
    },
    lookup(value) {
      this.$store
        .dispatch("apiRequest", {
          method: "GET",
          endpoint: this.searchRoute,
          params: { term: value },
        })
        .then(() => {
          this.results = this.$store.getters.apiResponse.data.data;
        });
    },
    saveItem(item) {
      this.$store
        .dispatch("apiRequest", {
          method: "POST",
          endpoint: this.itemsRoute,
          params: item,
        })
        .then(() => {
          this.search = null;
          this.items = this.$store.getters.apiResponse.data;
        });
    },
    deleteItem(link_id) {
      this.loading = true;
      this.$store
        .dispatch("apiRequest", {
          method: "DELETE",
          endpoint: this.itemsRoute + "/" + link_id,
          params: [],
        })
        .then(() => {
          this.getItems();
          this.loading = false;
        });
    },
  },
};
</script>

<style></style>
