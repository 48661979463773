<template>
  <v-card class="mb-1" flat>
    <v-card
      @click="
        $store.commit('setSideDrawerRequest', {
          endpoint: data.url,
          type: 'article',
        })
      "
      flat
      :style="
        ['live', 'closed', 'active'].includes(data.status)
          ? 'opacity:1;'
          : 'opacity:0.3'
      "
      class="pa-0 d-flex align-center"
    >
      <v-card-title class="pa-3">
        <div class="article-label">
          <div
            v-if="data.overline"
            class="text-overline"
            v-html="data.overline"
          ></div>
          <div v-if="data.title" class="text-h3">
            {{ data.title }}
          </div>
          <!-- <div v-if="data.headline" class="caption" v-html="data.headline"></div> -->
        </div>
      </v-card-title>
    </v-card>
    <elements-action-btns :actions="data.actions" />
  </v-card>
</template>

<script>
export default {
  props: ["data"],
};
</script>

<style scoped>
.v-card {
  background-color: transparent;
}
.article-label {
  border-left: 4px solid #388437;
  padding-left: 10px;
}
</style>
