<template>
  <v-container>
    <p v-if="message">{{ message }}</p>
    <div v-else>
      <div class="text-center pa-4">
        <v-progress-circular
          indeterminate
          class="mx-auto"
          :size="70"
          :width="7"
        />
        <div class="text-h2">Processing...</div>
      </div>
    </div>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    message: null,
  }),
  mounted() {
    this.getRequest();
  },

  methods: {
    async getRequest() {
      try {
        this.loading = true;
        const response = await axios.get(
          this.$route.fullPath.replace("request/", "")
        );
        if (response.data.next && response.data.next.type == "redirect") {
          this.$router.push({
            path: response.data.next.route,
            query: { ud: Date.now() },
          });
        }
        this.message = response.data;
      } catch (error) {
        this.message = error;
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
