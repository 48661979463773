<template>
  <v-card
    class="d-flex align-center"
    :to="school.url"
    flat
    style="border: 1px solid #b9b9b9"
  >
    <v-img
      v-if="school.hero"
      :src="school.hero.src_small"
      :alt="school.title"
      max-height="100"
      max-width="100"
      contain
      class="ml-2"
    />

    <v-list-item>
      <v-list-item-content>
        <span class="caption">{{ school.authority_name }}</span>

        <v-list-item-title v-if="school.name" class="text-h5 mb-2 text-wrap">
          {{ school.name }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>

<script>
export default {
  props: ["school"],
};
</script>

<style lang="scss" scoped>
.v-card {
  margin-bottom: 20px;
}
</style>
