import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const entities = [
  '/dashboard',
  '/dashboard/:element',
  '/users/:id',
  '/users/:id/:element',
  '/contributors/:id',
  '/contributors/:id/:element',
  '/schools/:id',
  '/schools/:id/:element',
  '/article-editor/:id',
  '/article-editor/:id/:element',
  '/polls/:id',
  '/polls/:id/:element',
  '/groups/:id',
  '/groups/:id/:element',
  '/account',
  '/account/:element',

];
const infinite = [
  '/newsfeed',
  '/schools',
  '/contributors',
  '/topics',
  '/topics/:topic',
  '/groups',
  '/support',
  '/support/:element',
];
const forms = [
  '/register',
  '/login',
  '/reports/create',
  '/forgot-password',
  '/reset-password/:token',
  '/invitation/:acount',
  '/update-my-data',
  '/articles/:id/edit',
  '/unsubscribe/:user',
  '/schools/:school/students/:student/invitation',
  '/schools/:school/members/:member/invitation',
  '/contributors/:contributor/members/:member/invitation',
];

const routes = []
for (let i = 0; i < entities.length; i++) { 
  routes.push({
    path: entities[i],
    component: require('../components/pages/EntityView.vue').default,
  });
}
for (let i = 0; i < infinite.length; i++) { 
  routes.push({
    path: infinite[i],
    component: require('../components/pages/InfiniteView.vue').default,
  });
}
for (let i = 0; i < forms.length; i++) { 
  routes.push({
    path: forms[i],
    component: require('../components/form/GetForm.vue').default,
  });
}

routes.push(
  {
    path: '/',
    component: require('../components/pages/HomeView.vue').default,
  },
  {
    path: '/articles/:slug',
    component: require('../components/pages/ArticleView.vue').default,
  },
  {
    path: '/file/:id',
    component: require('../components/pages/MediaView.vue').default,
  },
  {
    path: "/request/:type",
    component: require('../components/pages/RequestView.vue').default,
  },
  {
    path: '/subscriptions',
    component: require('../components/pages/SubscriptionsView.vue').default,
  },
  {
    path: '/503',
    component: require('../components/pages/ServiceDown.vue').default,
  },
  { 
    path: '/:pathMatch(.*)*', 
    component: require('../components/pages/PathNotFound').default
  },
);


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
      document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  }
})

export default router
