<template>
  <div v-if="items">
    <v-tabs
      v-if="items.length > 1"
      v-model="selectedTab"
      center-active
      show-arrows
    >
      <v-tab
        v-for="(header, index) in items"
        :key="index"
        :disabled="header.disabled || temporarilyDisabled"
      >
        <v-chip v-if="header.count" small class="mr-1">{{
          header.count
        }}</v-chip>

        {{ header.label }}

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-if="header.isPrivate"
              color="grey lighten-1"
              v-bind="attrs"
              v-on="on"
              class="ml-1"
              small
            >
              mdi-account-lock
            </v-icon>
          </template>
          <span>{{ header.isPrivate }}</span>
        </v-tooltip>
        <v-icon v-if="header.warning" color="red" class="ml-1">
          mdi-alert-circle
        </v-icon>
      </v-tab>
    </v-tabs>

    <div v-if="tabData">
      <elements-content-block
        v-if="tabData.type == 'content'"
        :data="tabData"
      />
      <form-get-form
        v-else-if="tabData.type == 'form'"
        :route="tabData.endpoint"
      />
      <elements-linked-manager
        v-else-if="tabData.type == 'linkManager'"
        :item="tabData.item"
        :id="tabData.id"
      />
      <elements-results-block
        v-else-if="tabData.type == 'results'"
        :route="tabData.endpoint"
      />
      <elements-stats-block
        v-else-if="tabData.type == 'turnout'"
        :route="tabData.endpoint"
      />
      <elements-subscription-block
        v-else-if="tabData.type == 'subscriptions'"
        :data="tabData.data"
      />
      <elements-infinite-results v-else :data="tabData" masonry />
    </div>
  </div>
</template>

<script>
export default {
  props: ["items"],

  data() {
    return {
      selectedTab: null,
      tabData: null,
      temporarilyDisabled: false,
    };
  },

  mounted() {
    this.setSelectedTab();
  },

  methods: {
    fetchTabData() {
      if (this.items?.length == 0 || this.selectedTab === null) {
        this.tabData = null;
        return;
      }

      this.tabData = this.items[this.selectedTab];
      history.pushState(null, "", this.tabData.endpoint);
      // console.log(this.tabData.endpoint);
    },

    setSelectedTab() {
      this.selectedTab = this.items?.length > 0 ? 0 : null;
    },
  },

  watch: {
    selectedTab() {
      if (!this.temporarilyDisabled) {
        this.temporarilyDisabled = true;
        setTimeout(() => {
          this.temporarilyDisabled = false;
        }, 1500);
      }
      this.fetchTabData();
    },
    items() {
      if (this.items?.length == 0) return false;
      if (this.$route.params.element) {
        const index = this.items.findIndex(
          (obj) => obj.endpoint === this.$route.path
        );
        if (index !== -1) {
          this.selectedTab = index;
        }
      } else {
        this.setSelectedTab();
      }
    },
  },
};
</script>

<style scoped></style>
