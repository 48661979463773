<template>
  <v-card
    flat
    @click="
      $store.dispatch('processAction', { type: 'image', endpoint: media.url })
    "
  >
    <v-img
      v-if="media.src_medium"
      :src="media.src_medium"
      :alt="media.name"
      max-width="350"
      @error="media.src_medium == null"
    />
    <span v-else class="pa-4">Image failed to load</span>
  </v-card>
</template>

<script>
export default {
  props: ["media"],
};
</script>

<style lang="scss" scoped>
.v-card {
  margin-bottom: 20px;
}
</style>
