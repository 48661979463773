<template>
  <v-card class="mb-5 generic-card" flat>
    <v-chip v-if="!['confirmed'].includes(student.status)" small label>
      {{ student.status }}
    </v-chip>

    <v-card-title
      class="d-flex align-center hover"
      @click="$router.push(student.url)"
    >
      <v-avatar
        size="40"
        class="mr-2"
        color="grey"
        v-if="student.user && student.user.avatar"
      >
        <v-img
          :src="student.user.avatar.src_small"
          :alt="student.user.avatar.name"
        />
      </v-avatar>
      <div>
        <div class="text-h5 text-wrap">{{ student.name }}</div>
        <div class="caption">{{ student.age }}</div>
        <div v-if="student.email" class="caption">{{ student.email }}</div>
      </div>
    </v-card-title>

    <v-card-text
      v-if="
        (student.groups && student.groups.length > 0) || student.permissions
      "
    >
      <div v-if="student.groups && student.groups.length > 0">
        <v-chip
          v-for="(group, index) in student.groups"
          :key="index"
          color="primary"
          :to="group.url"
          small
          class="mr-1"
        >
          {{ group.label }}
        </v-chip>
      </div>
      <cards-permissions-template :permissions="student.permissions" />
    </v-card-text>

    <elements-action-btns
      v-if="student.actions"
      :actions="student.actions"
      size="small"
    />
  </v-card>
</template>

<script>
export default {
  props: ["student"],
};
</script>

<style lang="scss" scoped>
.generic-card {
  border: 1px solid #b9b9b9;
}
.hover:hover {
  cursor: pointer;
}
</style>
