<template>
  <div>
    <div v-if="loading" class="text-center pa-7">
      <v-progress-circular
        indeterminate
        class="mx-auto"
        :size="70"
        :width="7"
      />
    </div>
    <div v-if="article.title">
      <elements-hero-image :hero="article.hero" :name="article.title" />
      <v-container>
        <div class="px-1">
          <router-link class="article_title" :to="article.url">{{
            article.title
          }}</router-link>
          <div class="article_subtitle">{{ article.headline }}</div>
          <br />
          <div v-html="article.content" class="ql-editor-custom"></div>

          <!-- <div v-if="article.vote_btn" class="mb-5 text-center">
          <div class="caption ml-2">{{ article.vote_btn.label }}</div>
          <elements-action-btns :actions="article.vote_btn.actions" />
        </div> -->
        </div>
        <elements-topics-block :topics="article.topics" class="py-4" />
        <v-spacer></v-spacer>
        <elements-action-btns :actions="article.actions" />
      </v-container>
    </div>
    <div v-if="error" class="pa-3">
      There was an error loading content. {{ endpoint }} - {{ error }}
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: ["endpoint"],

  data: () => ({
    error: false,
    loading: false,
    article: {},
  }),
  watch: {
    endpoint: {
      handler() {
        this.error = false;
        this.getArticle();
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    async getArticle() {
      if (!this.endpoint) return;
      try {
        this.article = [];
        this.loading = true;
        const response = await axios.get(this.endpoint);
        this.article = response.data;
      } catch (error) {
        console.log(error);
        // this.error = error.response
        //   ? error.response.data.message
        //   : error.response.statusText;
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.article_title {
  font-size: 1.6em;
  font-weight: 300;
  text-decoration: none;
  color: inherit;
}
.article_subtitle {
  font-size: 1em;
  opacity: 0.6;
}
.ql-editor-custom {
  margin: 2% auto 5% auto;
  padding: 0 0;
  font-size: 16px;
  line-height: 25px;
  p {
    margin-bottom: 0;
  }
  blockquote {
    font-size: 1.2em;
    width: 90%;
    margin: 0 auto;
    font-style: italic;
    color: #555555;
    padding: 1.2em 30px 1.2em 75px;
    border-left: 8px solid #78c0a8;
    line-height: 1.5;
    position: relative;
    background: #ededed;
  }
  .ql-size-small {
    /* font-size: 0.75em; */
    font-size: 10px;
  }

  .ql-size-large {
    /* font-size: 1.5em; */
    font-size: 14px;
  }

  .ql-size-huge {
    /* font-size: 2.5em; */
    font-size: 16px;
  }
}
</style>
