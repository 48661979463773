<template>
  <v-container
    style="
      position: fixed;
      bottom: 0;
      left: 10px;
      max-width: 350px;
      z-index: 200001;
    "
    v-if="notifications.length > 0"
  >
    <v-alert
      v-for="(item, index) in notifications"
      :key="index"
      dense
      dismissible
      transition="fade-transition"
      :type="item.status"
    >
      <div class="message text-wrap" v-html="item.message"></div>
    </v-alert>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      notifications: [],
      alert: true,
    };
  },
  watch: {
    "$store.state.notifications": function () {
      this.notifications.push(this.$store.state.notifications);
      setTimeout(
        () =>
          this.notifications.splice(
            this.notifications.indexOf(this.$store.state.notifications),
            1
          ),
        10000
      );
    },
  },
};
</script>

<style scoped>
.message {
  word-break: break-word;
}
</style>
