<template>
  <v-container>
    <h1>Ops...</h1>
    <h2>Did you enter the correct address?</h2>
    <p></p>
    <p>
      404
      <router-link :to="failedPath"
        >https://schdoo.com{{ failedPath }}</router-link
      >
      does not exist
    </p>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      failedPath: "",
    };
  },
  created() {
    this.failedPath = this.$route.query.ref || "";
  },
};
</script>

<style></style>
