import { render, staticRenderFns } from "./EntityView.vue?vue&type=template&id=4b2f1598&scoped=true"
import script from "./EntityView.vue?vue&type=script&lang=js"
export * from "./EntityView.vue?vue&type=script&lang=js"
import style0 from "./EntityView.vue?vue&type=style&index=0&id=4b2f1598&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b2f1598",
  null
  
)

export default component.exports