<template>
  <div class="d-flex align-content-center">
    <v-tooltip top v-for="(item, index) in items" class="mx-1" :key="index">
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          color="lighten-1"
          :color="item.colour ? item.colour : 'grey'"
          v-bind="attrs"
          v-on="on"
          class="tooltip mx-1"
        >
          {{ item.icon }}
        </v-icon>
      </template>
      <span v-html="item.label"></span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: ["items"],
};
</script>

<style lang="scss" scoped>
.tooltip:hover {
  cursor: help;
}
</style>
