import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'
import VueCookie from 'vue-cookie'
import axios from 'axios';

Vue.use(Vuex)
Vue.use(VueCookie);

axios.defaults.baseURL = (process.env.NODE_ENV == 'development')? 'http://localhost:8001/api' : 'https://api.schdoo.com';
axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['Authorization'] = `Bearer ${VueCookie.get('bearer_token')}`;

export default new Vuex.Store({
  state: {
    refreshRouterViewKey: 0,
    failCount       : 0,
    isLoading       : false,
    activeUser      : [],
    uploaderDialog  : false,
    uploaderData    : [],
    sideDrawerActive: false,
    sideDraweRequest : {},
    dialogActive    : false,
    dialogRequest   : {},
    apiResponse     : [],
    notifications   : null,
    form            : [],
    hasErrors       : [],
  },
  getters: {
    isLoading       : (state) => state.isLoading,
    activeUser      : (state) => state.activeUser,
    uploaderDialog  : (state) => state.uploaderDialog,
    uploaderData    : (state) => state.uploaderData,
    sideDrawerActive: (state) => state.sideDrawerActive,
    sideDraweRequest: (state) => state.sideDraweRequest,
    dialogActive    : (state) => state.dialogActive,
    dialogRequest   : (state) => state.dialogRequest,
    apiResponse     : (state) => state.apiResponse,
    notifications   : (state) => state.notifications,
    form            : (state) => state.form,
    hasErrors       : (state) => state.hasErrors,
  },
  mutations: {
    refreshRouterView(state) { state.refreshRouterViewKey++; },
    setLoading          : (state, isLoading)      => (state.isLoading = isLoading),
    setActiveUser       : (state, activeUser)     => (state.activeUser = activeUser),  
    setApiResponse      : (state, apiResponse)    => (state.apiResponse = apiResponse),
    setUploaderDialog   : (state, uploaderDialog) => { state.uploaderDialog = uploaderDialog },
    setUploaderData     : (state, uploaderData)   => { state.uploaderData = uploaderData },
    setSideDrawerActive : (state, sideDrawerActive) => { state.sideDrawerActive = sideDrawerActive },
    setSideDrawerRequest    : (state, data)         => { 
        state.sideDrawerActive  = true;  
        state.sideDraweRequest    = {
          endpoint : data.endpoint,
          type     : data.type,
          data     : data,
        }; 
    },
    setDialogActive     : (state, dialogActive)   => { state.dialogActive = dialogActive },
    setDialogRequest    : (state, data)         => { 
                          state.dialogActive  = true;  
                          state.dialogRequest    = {
                            route : data.route,
                            type  : data.type,
                          }; 
                      },
    
    setNotes           : (state, notifications)   => { state.notifications = notifications },
    setErrors          : (state, hasErrors)       => { state.hasErrors = hasErrors },
    setForm            : (state, form)            => { state.form = form },
    setFormValue(state, { fieldName, value }) {
      if (state.hasErrors && state.hasErrors.hasOwnProperty(fieldName)) {
        Vue.delete(state.hasErrors, fieldName);
      }
      if (!state.form.values) {
        Vue.set(state.form, 'values', {});
      }
      if (!Object.prototype.hasOwnProperty.call(state.form.values, fieldName)) {
        Vue.set(state.form.values, fieldName, '');
      }

      Vue.set(state.form.values, fieldName, value);

    },
    updateFormField(state, { fieldName, options }) {
      const fieldIndex = state.form.fields.findIndex(f => f.name === fieldName);
      if (fieldIndex !== -1) {
        Vue.set(state.form.fields[fieldIndex], 'options', options);
      }
    },
  },
  actions: {
    setLoading(state) {
      state.commit('setLoading')
    },
    setForm(state){
      state.commit('setForm')
    },
    async apiRequest(a, data){

      try {
            let res;
            const params = new URLSearchParams(data.params).toString();

            switch(data.method){
              case 'GET':     res = await axios.get(data.endpoint+'?'+params); break;
              case 'POST':    res = await axios.post(data.endpoint, data.params); break;
              case 'PATCH':   res = await axios.patch(data.endpoint, data.params); break;
              case 'DELETE':  res = await axios.delete(data.endpoint, data.params); break;
              default: console.log('No axios method supplied'); break;
            }            

            if(typeof res.data?.message !== 'undefined'){
              this.commit('setNotes', {'message': res.data.message, 'status':'success'} );
            }
            
            if([200, 201].includes(res.status)){
              this.commit('setApiResponse', res);
            }
            else{
              console.log(data.endpoint);
              console.log('unexpected status '.res.status);
              console.log(res);
            }

            // Token
            if(typeof res.data.bearer_token !== 'undefined'){ 
              const token = res.data.bearer_token;
              if(token == VueCookie.get('bearer_token')){
                VueCookie.delete('bearer_token');
              }
              VueCookie.set('bearer_token', token, { expires: '30D' });
              axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            }
            if(typeof res.data.user_active !== 'undefined'){ 
              this.commit('setActiveUser', res.data.user_active);       
            }

            // Actions
            if(res.data.next){ 
              this.commit('setUploaderDialog', false)
              this.commit('setDialogActive', false)

              if(res.data.next.type == 'redirect'){ 
                router.push({
                  path: res.data.next.route,
                  query: { ud: Date.now() } 
                });
              }
              else if(res.data.next.type == 'direct'){ 
                location.replace(res.data.next.route);
              }
              else if(res.data.next.type == 'opentab'){
                window.open(data.endpoint, '_blank', 'noopener, noreferrer');
              }
              else if(res.data.next.type == 'reload'){
                this.commit('refreshRouterView');
              }
              else if(res.data.next.type == 'lastPage'){
                router.go(-1);
              }
              else if(res.data.next.type == 'closeDialog'){
                this.commit('setUploaderDialog', false)
              }
              else if(res.data.next.type == 'mediaUploader'){
                this.commit('setUploaderData', res.data.next)
              }
              else if(['form'].includes(res.data.next.type)){ 
                this.commit('setDialogRequest', {route : res.data.next.route, type : res.data.next.type})
              }
              else{ console.log(res.data.next); }
          
            }
            this.commit('setLoading', false);

      } 
      catch (error) {
        this.commit('setLoading', false);
 
        if(typeof error.response == 'undefined'){
          if(this.state.failCount > 3 && router.history.current.path !== '/503'){
            this.state.failCount = 0;
            router.push('/503');
          } 
          console.log('No response assuming server error');
          console.log(error);
          this.state.failCount++;
          console.log(this.state.failCount);
          return;
        }

        if(typeof error.response.data.errors !== 'undefined'){
          this.commit('setErrors', error.response.data.errors);
        } 

        if(typeof error.response.status !== 'undefined'){
          console.log('Unexpected caught an error');
          console.log(error.response);
          console.log(error.request);
        }

        if(error.response.status == 401){
          router.push({ path: '/login', query: { ref: router.history.current.path } });
          return;
        }
        if(error.response.status == 402){
          router.push({ path: '/subscriptions', query: { ref: router.history.current.path } });
          return;
        }
        if(error.response.status == 404){
          router.push({ path: '/404', query: { ref: router.history.current.path } });
          return;
        }

        let message = errorCode(error.response.status);
        if(error.response.data?.message) message = error.response.data.message;
        if(error.response.data?.error)   message = error.response.data.error;
        this.commit('setNotes', {'message': message, 'status':'error'} );          

      }

    },
    redirect(e, endpoint){
      this.commit('setDialogActive', false);
      router.push({
        path: endpoint,
        query: { ud: Date.now() } 
      });
      return;
    },
    processAction(e, data){

      if(['form', 'image'].includes(data.type)){
        this.commit('setDialogRequest', {route : data.endpoint, type : data.type})
        return;
      }

      if(data.action == 'sideDrawer'){
        this.commit('setSideDrawerRequest', data)
        return;
      }

      if(data.action == 'redirect'){
        this.commit('setDialogActive', false);
        router.push({
          path: data.endpoint,
          query: { ud: Date.now() } 
        });
        return;
      }
      if(data.action == 'direct'){
        this.commit('setDialogActive', false);
        window.open(data.endpoint, '_blank', 'noopener, noreferrer');
        return;
      }


      
      if(data.action == 'action'){
        let methodType = (data.method)? data.method : 'POST';
        this.dispatch('apiRequest', {endpoint : data.endpoint, method : methodType })
        return;
      }
      if(data.action == 'delete'){
        this.dispatch('apiRequest', {endpoint : data.endpoint, method : 'DELETE' })
        return;
      }

      if(data.action == 'mediaUploader'){
        this.commit('setUploaderDialog', true);
        this.commit('setUploaderData', { item: data.item, id: data.id })
        return;
      }

      console.log(e);
      console.log(data);

    },
    updateFormFieldOptions({ commit }, { fieldName, options }) {
      commit('updateFormField', { fieldName, options });
    },
    setFormValue({ commit }, { fieldName, value }) {
      commit('setFormValue', { fieldName, value });
    },
  }

})


function errorCode(code){
  if(code == 400) return 'Failed Validation'; 
  if(code == 401) return 'Login required'; 
  if(code == 402) return 'Subscription required'; 
  if(code == 403) return 'Permission denied'; 
  if(code == 404) return 'Not Found'; 
  if(code == 415) return 'Unsupported Media Type';
  if(code == 422) return 'Unprocessable'; 
  if(code == 429) return 'Too many requests';
  if(code == 500) return 'Server error';
  if(code == 503) return 'Service unavailable';
  return 'unknown '+code;
}