<template>
  <div class="option_container text-center">
    <div v-if="error">{{ error }}</div>
    <div v-else>
      <div class="d-flex align-center justify-space-between child-flex my-2">
        <div class="mr-4">
          <v-text-field
            type="text"
            v-model="new_option"
            :label="
              options.length < 1 ? 'Enter an option' : 'Enter another option'
            "
            :error-messages="error_message"
            @keydown.enter.prevent="addOption"
          ></v-text-field>
        </div>
        <v-btn color="success" type="button" @click="addOption" outlined>
          <v-icon small>mdi-plus</v-icon>
          Add
        </v-btn>
      </div>

      <draggable
        v-model="options"
        @end="updateOrder"
        handle=".drag-handle"
        dragClass="dragging"
        ghostClass="ghost-item"
      >
        <div
          v-for="(option, index) in options"
          :key="index"
          class="option_item d-flex align-center my-2 noselect drag-handle"
        >
          <v-avatar
            size="60"
            color="grey"
            @click="
              $store.dispatch('processAction', {
                action: 'mediaUploader',
                item: 'option',
                id: option.id,
              })
            "
          >
            <v-img
              v-if="option.image"
              :src="option.image.src_medium"
              :alt="option.image.name"
            />
            <span v-else class="white--text"
              ><v-icon color="white" large>mdi-image</v-icon></span
            >
          </v-avatar>

          <div
            class="option_label text-left flex-fill"
            v-html="option.label"
          ></div>

          <v-icon large class="drag-icon">mdi-unfold-more-horizontal</v-icon>
          <div class="text-right">
            <v-btn type="button" plain @click="deleteOption(option.id)">
              <v-icon large>mdi-close</v-icon>
            </v-btn>
          </div>
        </div>
      </draggable>
    </div>
    <v-progress-circular
      v-if="loading"
      indeterminate
      class="mt-4"
    ></v-progress-circular>
  </div>
</template>

<script>
import draggable from "vuedraggable";
export default {
  components: {
    draggable,
  },
  props: ["route"],
  data() {
    return {
      loading: false,
      options: [],
      new_option: "",
      error: false,
    };
  },
  mounted() {
    this.getOptions();
  },
  computed: {
    error_message() {
      return this.$store.getters.hasErrors["label"]
        ? this.$store.getters.hasErrors["label"]
        : null;
    },
  },
  methods: {
    getOptions() {
      this.loading = true;
      this.$store
        .dispatch("apiRequest", {
          method: "GET",
          endpoint: this.route,
        })
        .then(() => {
          this.loading = false;
          this.options = this.$store.getters.apiResponse.data;
        });
    },
    addOption() {
      this.loading = true;
      this.$store.commit("setErrors", []);

      this.$store
        .dispatch("apiRequest", {
          method: "POST",
          endpoint: this.route,
          params: { label: this.new_option },
        })
        .then(() => {
          this.options = this.$store.getters.apiResponse.data;
        });
      this.new_option = "";
      this.loading = false;
    },
    updateOrder() {
      const orderedOptions = this.options.map((option, index) => ({
        id: option.id,
        order: index,
      }));

      this.$store.dispatch("apiRequest", {
        method: "POST",
        endpoint: `${this.route}-order`,
        params: { orderedOptions },
      });
    },
    deleteOption(id) {
      this.loading = true;
      this.$store.commit("setErrors", []);

      this.$store
        .dispatch("apiRequest", {
          method: "DELETE",
          endpoint: this.route + "/" + id,
        })
        .then(() => {
          this.options = this.$store.getters.apiResponse.data;
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.option_container {
  display: block;
  position: relative;
  max-width: 600px;
  margin: 2% auto 5% auto;
}
.option_item {
  margin: 0 auto;
  padding: 0;
  border-radius: 50px;
  width: 100%;
  max-width: 500px;
  max-height: 73px;
  border: 1px solid #a5a5a5;
  transition: transform 0.5s;

  .option_label {
    padding: 5px 15px;
    max-width: 320px;
    overflow: hidden;
  }
  .v-avatar {
    cursor: pointer;
  }
}
.dragging {
  background-color: #b9b9b9;
  border: 2px solid #a5a5a5;
}
.ghost-item {
  opacity: 0.2;
}
.drag-handle {
  max-height: 70px;
  cursor: move;
}
.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>
