<template>
  <v-card class="mb-5 generic-card" flat>
    <v-card
      flat
      v-if="data.author || data.representing || data.status || data.user"
    >
      <v-card-title
        class="d-flex align-center justify-space-between py-2 flex-nowrap"
      >
        <elements-author-block
          v-if="data.author || data.representing"
          :user="data.author"
          :representing="data.representing"
        />
        <elements-user-avatar :user="data.user" />
        <v-spacer></v-spacer>

        <cards-tooltip-icons :items="data.icons" />

        <v-chip
          v-if="
            data.status &&
            ![
              'live',
              'active',
              'confirmed',
              'closed',
              'read',
              'unread',
            ].includes(data.status)
          "
          label
          color="error"
          small
        >
          <v-icon v-if="data.is_hidden" class="mr-1" small>
            mdi-eye-off-outline
          </v-icon>
          {{ data.status }}</v-chip
        >

        <elements-drop-menu :menu="data.menu" size="small" />
      </v-card-title>
    </v-card>

    <v-card :to="data.url" flat>
      <v-card-title class="py-1 d-flex flex-nowrap">
        <v-avatar v-if="data.avatar" size="50" class="mr-2">
          <v-img
            v-if="data.avatar"
            :src="data.avatar.src_large"
            :alt="data.title"
            fluid
          />
        </v-avatar>

        <div>
          <div
            v-if="data.overline"
            class="text-overline"
            v-html="data.overline"
          ></div>
          <div v-if="data.title" class="text-h3" v-html="data.title"></div>
          <div v-if="data.label" class="text-h3" v-html="data.label"></div>
          <div
            v-if="data.sublabel"
            class="text-caption"
            v-html="data.sublabel"
          ></div>
          <div
            v-if="data.subtitle"
            class="text-overline"
            v-html="data.subtitle"
          ></div>
          <div v-if="data.timestamp" class="caption">
            {{ data.timestamp | moment("from") }}
          </div>
        </div>
      </v-card-title>

      <v-card-text v-if="data.groups && data.groups.length > 0" class="my-2">
        <v-chip
          v-for="(group, index) in data.groups"
          :key="index"
          color="primary"
          :to="group.url"
          small
          class="mr-1"
        >
          {{ group.label }}
        </v-chip>
      </v-card-text>

      <v-card-text
        v-if="
          data.items ||
          data.type ||
          data.options ||
          data.content ||
          data.permissions
        "
        class="card-content"
      >
        <cards-permissions-template :permissions="data.permissions" />

        <div v-if="data.content" class="text-p" v-html="data.content"></div>

        <div v-for="(item, index) in data.items" :key="index">
          <div class="py-1">
            <strong>{{ item.label }}<br /></strong>{{ item.content }}
          </div>
        </div>

        <div v-if="data.options && data.options.length" class="options_list">
          <v-list-item
            v-for="(option, index) in data.options.slice(0, 2)"
            :key="index"
          >
            <v-list-item-content>
              <v-list-item-title>
                {{ option.label }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="data.options.length === 3">
            <v-list-item-content>
              <v-list-item-title>
                {{ data.options[2].label }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="data.options.length > 3">
            <v-list-item-content>
              <v-list-item-title>
                + {{ data.options.length - 2 }} more
                {{ data.options.length - 2 === 1 ? "option" : "options" }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>

        <v-list-item v-if="data.ends_at">
          <v-list-item-content>
            <v-list-item-subtitle
              v-if="data.ends_at"
              class="overline text-center"
            >
              <span v-if="data.ends_at">
                <span class="red--text">
                  {{ data.ends_at_label }}
                  {{ data.ends_at | moment("from") }}
                </span>
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card-text>

      <v-img
        v-if="data.hero"
        :src="data.hero.src_large"
        :alt="data.title"
        width="100%"
        fluid
      />

      <elements-action-btns :actions="data.actions" />
    </v-card>

    <!-- <cards-stats-icons :item="data" /> -->
  </v-card>
</template>

<script>
export default {
  props: ["data"],
};
</script>

<style lang="scss" scoped>
.generic-card {
  border: 1px solid #b9b9b9;
}
.v-card {
  background: transparent !important;
  background-color: transparent !important;
}
.full-width-card {
  width: 100%;
}
.text-overline {
  line-height: 1rem;
}

/* .card-content {
  min-height: 140px;
} */

.options_list {
  .v-list-item {
    margin: 10px 0 0 0;
    border-radius: 35px;
    border: 1px solid #b9b9b9;
    min-height: 30px;
  }
  .v-list-item__content {
    padding: 0;
  }
  .v-list-item__title {
    padding: 6px 0px;
  }
}
</style>
