<template>
  <div class="my-3">
    <div class="text-center" v-if="loading">
      <v-progress-circular indeterminate class="mt-4" :size="70" :width="7" />
    </div>
    <div v-if="content" class="py-3">
      <div class="text-h2 mb-7" v-if="content.header">{{ content.header }}</div>
      <div class="mb-7" v-if="content.body">{{ content.body }}</div>

      <div v-if="content.items">
        <v-row v-if="content.items.length > 0" class="links">
          <v-col
            v-for="(item, index) in content.items"
            :key="index"
            :cols="content.items.length > 3 ? '12' : '12'"
            :md="content.items.length > 3 ? '4' : undefined"
            class="item-col"
          >
            <div class="d-flex align-center">
              <v-icon
                v-if="typeof item.completed !== 'undefined'"
                class="mr-3"
                :color="item.completed ? 'success' : 'error'"
                >{{
                  item.completed
                    ? "mdi-check-circle-outline"
                    : "mdi-alert-circle-outline"
                }}</v-icon
              >
              <div>
                <div v-if="item.type == 'url'">
                  <span v-if="item.label">
                    <strong>{{ item.label }}</strong></span
                  ><br />
                  <a
                    v-if="item.type == 'url'"
                    :href="item.url"
                    target="_blank"
                    >{{ item.urllabel ? item.urllabel : item.url }}</a
                  >
                </div>
                <div v-else>
                  <span v-if="item.label">
                    <strong>{{ item.label }}</strong
                    ><br
                  /></span>
                </div>

                <span v-if="item.type == 'text'" v-html="item.content" />
                <span v-if="item.type == 'date'">{{
                  item.content | moment("from")
                }}</span>

                <div v-if="item.type == 'list'">
                  <div v-for="(listItem, index) in item.content" :key="index">
                    <a v-if="listItem.url" :href="listItem.url">
                      <strong v-if="listItem.label">{{
                        listItem.label
                      }}</strong>
                    </a>
                    <span v-else>
                      <strong v-if="listItem.label">{{
                        listItem.label
                      }}</strong>
                    </span>
                    <span v-if="listItem.count"> ({{ listItem.count }})</span>

                    <span v-if="listItem.timestamp">
                      {{ listItem.timestamp | moment("from") }}</span
                    >
                  </div>
                </div>

                <elements-user-avatar
                  v-if="item.type == 'avatar'"
                  :user="item.content"
                />

                <elements-topics-block
                  v-if="item.type == 'topics'"
                  :topics="item.content"
                />
              </div>
              <elements-action-btns :actions="item.actions" />
            </div>
          </v-col>
        </v-row>
      </div>

      <cards-permissions-template :permissions="content.permissions" />

      <div class="my-5">
        <elements-action-btns :actions="content.actions" />
      </div>
    </div>
    <div v-if="error" class="py-3">
      There was an error loading content. {{ data.endpoint }} - {{ error }}
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: ["data"],
  data() {
    return {
      loading: false,
      error: false,
      content: [],
      options: {},
    };
  },
  computed: {},
  watch: {
    data: {
      handler() {
        this.content = false;
        this.error = false;
        this.getDetails();
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    async getDetails() {
      if (!this.data.endpoint) {
        this.content = this.data.data;
        return;
      }
      try {
        this.loading = true;

        let options = Object.fromEntries(
          Object.entries(this.options).filter(([, v]) => v != null)
        );
        const params = new URLSearchParams(options).toString();

        const response = await axios.get(this.data.endpoint + "?" + params);
        this.content = response.data;
      } catch (error) {
        this.error = error.response.data.message;
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.item-col {
  min-width: 300px;
  padding: 10px;
}
</style>
