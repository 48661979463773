<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    persistent
  >
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar dark class="px-2">
          <v-btn @click="dialog = false">
            <v-icon class="mr-2">mdi-close</v-icon>
            Close
          </v-btn>
        </v-toolbar>
      </v-card-title>

      <v-container class="justify-center">
        <form-get-form
          v-if="dialogType == 'form'"
          :key="'form-' + route"
          :route="route"
        />
        <pages-media-view
          v-if="dialogType == 'image'"
          :key="'image-' + route"
          :route="route"
        />
        <div v-if="this.$store.state.isLoading" class="text-center pa-4">
          <v-progress-circular
            indeterminate
            class="mx-auto"
            :size="70"
            :width="7"
          />
        </div>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      route: null,
      dialog: this.$store.state.dialogActive,
      dialogType: null,
    };
  },
  watch: {
    "$store.state.dialogActive": function () {
      this.dialog = this.$store.state.dialogActive;
    },
    "$store.state.dialogRequest": function () {
      this.route = this.$store.state.dialogRequest.route;
      this.dialogType = this.$store.state.dialogRequest.type;
    },
    dialog(newVal) {
      if (newVal === false) {
        this.resetDialog();
      }
      if (newVal !== this.$store.state.dialogActive) {
        this.$store.commit("setDialogActive", newVal);
      }
    },
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    },
    resetDialog() {
      this.route = null;
      this.dialogType = null;
    },
  },
};
</script>

<style></style>
