<template>
  <div v-if="cookieWarning" class="cookie-banner">
    <div class="pa-2">We only use cookies for login and security</div>
    <div>
      <v-btn to="/articles/cookies" rounded class="mr-3">Find out more</v-btn>
      <v-btn @click="acceptCookies" rounded>Accept</v-btn>
    </div>
  </div>
</template>
<script>
import VueCookie from "vue-cookie";
export default {
  data: () => ({
    cookieWarning: true,
  }),
  mounted() {
    if (VueCookie.get("accepted_cookies")) {
      this.cookieWarning = false;
    }
  },
  methods: {
    acceptCookies() {
      VueCookie.set("accepted_cookies", true, { expires: "30D" });
      this.cookieWarning = false;
    },
  },
};
</script>
<style>
.cookie-banner {
  position: fixed;
  text-align: center;
  font-size: 16px;
  right: 15px;
  bottom: 15px;
  color: #fff;
  background-color: rgb(76, 102, 114);
  padding: 15px;
  z-index: 999;
  border-radius: 15px;
}
</style>
