<template>
  <v-footer class="footer" color="white">
    <v-container class="footer-content">
      <v-row class="align-center px-4" no-gutters>
        <div class="caption py-2">
          Something not right?
          <a
            @click="
              $store.dispatch('processAction', {
                action: 'redirect',
                endpoint: '/reports/create?path=' + currentRoute,
              })
            "
          >
            <strong>Let us know</strong>
          </a>
        </div>
      </v-row>
      <v-divider class="my-7"></v-divider>
      <v-row class="links">
        <v-col
          v-for="(items, category) in menuItems"
          :key="category"
          cols="12"
          md="3"
        >
          <v-list>
            <v-subheader class="text-h5" v-if="items.length > 0">{{
              category
            }}</v-subheader>
            <v-list-item v-for="item in items" :key="item.label" :to="item.url">
              <v-list-item-title class="text-h7">{{
                item.label
              }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-container>
    <div class="copyright">
      Copyright &#169; {{ new Date().getFullYear() }}
      <strong>Schdoo Ltd. </strong><br />Company No. 12494149. All Rights
      Reserved
    </div>
  </v-footer>
</template>

<script>
export default {
  data: () => ({
    menuItems: {
      Explore: [
        {
          label: "Topics",
          url: "/topics",
        },
        {
          label: "Groups",
          url: "/groups",
        },
        {
          label: "Contributors",
          url: "/contributors",
        },
        {
          label: "Schools",
          url: "/schools",
        },
      ],
      Resources: [
        {
          label: "For Voters",
          url: "/support/voters",
        },
        {
          label: "For Parents",
          url: "/support/parent-resources",
        },
        {
          label: "For Schools",
          url: "/support/school-resources",
        },
        {
          label: "For Contributors",
          url: "/support/contributors",
        },
      ],
      Legal: [
        {
          label: "Privacy & Data",
          url: "/support/privacy",
        },
        {
          label: "Cookies & Security",
          url: "/support/security",
        },
        {
          label: "Terms & Conditions",
          url: "/support/terms-and-conditions",
        },
      ],
      Company: [
        {
          label: "About",
          url: "/support/schdoo",
        },
        {
          label: "Subscriptions",
          url: "/subscriptions",
        },
        {
          label: "Support",
          url: "/support",
        },
        {
          label: "Contact",
          url: "/articles/contact",
        },
      ],
    },
  }),
  computed: {
    currentRoute() {
      return this.$router.currentRoute.fullPath;
    },
  },
};
</script>

<style scoped>
.footer {
  background-color: #fff;
  color: #000;
}
.footer-content {
  max-width: 1000px;
  margin: 0 auto 50px auto;
  padding: 2% 5%;
}
.links {
  @media (max-width: 600px) {
    padding-bottom: 80px;
  }
}
.v-list {
  background-color: inherit;
  color: inherit;
}
.v-list-subheader {
  background-color: inherit;
  color: inherit;
  height: 35px;
}
.v-list-item {
  min-height: 35px;
}
.v-list-item-title {
  color: inherit;
}
@media (max-width: 600px) {
  .v-col {
    max-width: 100%;
  }
}
.copyright {
  position: absolute;
  text-align: right;
  padding-top: 30px;
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
  right: 20px;
  bottom: 10px;
  @media (max-width: 600px) {
    bottom: 70px;
  }
}
</style>
