<template>
  <form-form-builder />
</template>

<script>
export default {
  props: ["route"],
  computed: {
    endpoint() {
      return this.route ? this.route : this.$route.fullPath;
    },
  },
  mounted() {
    this.getForm();
  },
  watch: {
    endpoint(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getForm();
      }
    },
  },
  methods: {
    getForm() {
      this.$store.commit("setLoading", true);
      this.$store.commit("setForm", {});
      this.$store
        .dispatch("apiRequest", {
          method: "GET",
          endpoint: this.endpoint,
        })
        .then(() => {
          this.$store.commit("setForm", this.$store.getters.apiResponse.data);
        });
    },
  },
};
</script>

<style></style>
