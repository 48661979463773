<template>
  <v-container fluid class="pa-0 ma-0 radioinput_container">
    <p>{{ input.label }}</p>
    <v-radio-group v-model="radio_input">
      <div
        class="text-center red--text pa-3"
        v-for="(msg, index) in error_message"
        :key="index"
      >
        {{ msg }}
      </div>

      <div>
        <v-radio
          v-for="option in input.options"
          :key="option.id"
          :label="option.label"
          :value="option.id"
          :checked="option.id == radio_input"
          :disabled="input.disabled"
          return-object
          :style="
            option.colour
              ? {
                  backgroundColor: option.colour,
                }
              : ''
          "
        >
          <template slot="label" v-if="option.image">
            <v-avatar size="60" class="mr-3" color="grey">
              <v-img :src="option.image.src_small" :alt="option.label" />
            </v-avatar>
            {{ option.label }}
          </template>
        </v-radio>
      </div>
    </v-radio-group>
  </v-container>
</template>

<script>
export default {
  props: ["input", "values"],
  computed: {
    radio_input: {
      get() {
        return this.value;
      },
      set(value) {
        this.$store.dispatch("setFormValue", {
          fieldName: this.input.name,
          value: value,
        });
      },
    },
    error_message() {
      return this.$store.getters.hasErrors[this.input.name]
        ? this.$store.getters.hasErrors[this.input.name]
        : null;
    },
  },
};
</script>

<style lang="scss">
.radioinput_container {
  .v-radio {
    margin: 0 auto;
    padding: 0;
    border-radius: 50px;
    width: 100%;
    max-width: 500px;
    // max-height: 73px;
    border: 1px solid #a5a5a5;
    .v-label {
      color: #000000;
      padding: 15px 15px 15px 25px;
      font-size: 1em;
    }

    &:hover {
      border: 1px solid #5eb556;
      .v-label {
        color: #000000;
      }
    }
  }
  .v-radio:has(.v-avatar) {
    .v-label {
      padding: 0;
    }
  }

  .v-input--selection-controls__input {
    display: none;
  }
  .v-item--active.v-radio {
    border: 1px solid #5eb556;
    background-color: #e4f2e5;
    font-weight: 500;
  }
  .v-radio--is-disabled {
    background-color: #ebebeb;
    pointer-events: visible;
    cursor: not-allowed !important;
    &:hover {
      border: 1px solid #b55656;
    }
  }
}
</style>
