<template>
  <v-card-actions class="actions py-0">
    <v-tooltip bottom v-if="item.votes_received !== undefined">
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on" class="action-item">
          <v-icon size="18" class="action-icon" aria-hidden="true"
            >mdi-vote</v-icon
          >
          <span class="action-number">{{ item.votes_received }}</span>
        </span>
      </template>
      <span>Votes recieved</span>
    </v-tooltip>

    <v-tooltip bottom v-if="item.score !== undefined">
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on" class="action-item">
          <v-icon size="18" class="action-icon" aria-hidden="true"
            >mdi-thumb-up-outline</v-icon
          >
          <span class="action-number">{{ item.score }}</span>
        </span>
      </template>
      <span>{{ item.score }} found this helpful</span>
    </v-tooltip>

    <v-divider
      v-if="item.age_range && (item.votes_received || item.score)"
      vertical
      class="action-divider"
    ></v-divider>

    <template v-if="item.age_range">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on" class="action-item">
            <v-icon size="18" class="action-icon" aria-hidden="true"
              >mdi-account-group</v-icon
            >
            <span class="action-number">{{ item.age_range }}</span>
          </span>
        </template>
        <span>Ideal for {{ item.age_range }}</span>
      </v-tooltip>
    </template>

    <template v-if="item.can_boost">
      <v-divider vertical class="action-divider"></v-divider>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            plain
            class="action-btn"
            @click="
              $store.dispatch('processAction', {
                action: 'action',
                endpoint: '/boost-post/' + item.post_id,
              })
            "
          >
            <v-icon size="18" class="action-icon" aria-hidden="true"
              >mdi-trending-up</v-icon
            >
          </v-btn>
        </template>
        <span>Boost</span>
      </v-tooltip>
    </template>
  </v-card-actions>
</template>

<script>
export default {
  props: ["item"],
};
</script>

<style lang="scss" scoped>
.actions {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 8px;
  min-height: 35px;
}

.action-item {
  display: flex;
  align-items: center;
  color: #a2a2a2;
  margin: 0 8px;
}
.action-btn {
  display: flex;
  align-items: center;
  color: #a2a2a2;
  min-width: 30px !important;
  min-height: 30px !important;
  margin: 0;
  padding: 0;
}

.action-icon {
  margin-right: 4px;
  color: #a2a2a2;
}

.action-number {
  font-size: 14px;
}

.action-divider {
  height: 24px;
}
</style>
