<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    max-width="700"
  >
    <v-card class="text-center">
      <div v-if="uploading" class="upload_container pa-3">
        <v-progress-circular
          indeterminate
          class="mx-auto mt-4"
          :size="70"
          :width="7"
        />
        <br />
        <span>Uploading file...</span>
      </div>

      <div v-else class="upload_container pa-3">
        <label
          for="file_uploader"
          @dragover="dragover"
          @dragleave="dragleave"
          @drop="drop"
        >
          <strong>Click to choose an image</strong>
          <span class="box__dragndrop"> or drag it here</span>

          <div v-if="errors.length > 0">
            <div
              class="red--text"
              v-for="(error, index) in errors"
              :key="index"
            >
              <span>{{ error }}</span>
            </div>
          </div>
        </label>
        <input
          type="file"
          name=""
          id="file_uploader"
          @change="handleFileChange($event)"
        />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: this.$store.state.mediaDialog,
      uploading: false,
      item: false,
      item_id: false,
      errors: [],
      isLoading: false,
      maxfileSize: 5,
      accept: "jpg,jpeg,png,heic",
      upload_limit: null,
      file: {
        name: "",
        size: 0,
        type: "",
        fileExtention: "",
        url: "",
      },
    };
  },
  watch: {
    "$store.state.uploaderDialog": function () {
      this.dialog = this.$store.state.uploaderDialog;
    },
    "$store.state.uploaderData": function () {
      this.item = this.$store.state.uploaderData.item;
      this.item_id = this.$store.state.uploaderData.id;
    },
    dialog() {
      if (this.dialog == this.$store.state.uploaderDialog) return;
      this.$store.commit("setUploaderDialog", this.dialog);
    },
  },
  computed: {
    acceptedFileTypes() {
      if (this.item == "new_students") return ["csv"];
      return ["jpg", "jpeg", "png", "heic", "webp"];
    },
  },
  methods: {
    handleFileChange(e) {
      this.errors = [];
      let file =
        typeof e.dataTransfer !== "undefined"
          ? e.dataTransfer.files[0]
          : e.target.files[0];

      const fileSize = Math.round((file.size / 1024 / 1024) * 100) / 100,
        fileExtention = file.name.split(".").pop().toLowerCase(),
        fileName = file.name.split(".").shift();

      if (fileSize >= this.maxfileSize) {
        this.errors.push(
          `File size should be less than ${this.maxfileSize} MB (${fileSize})`
        );
        return;
      }

      if (!this.acceptedFileTypes.includes(fileExtention)) {
        this.errors.push(
          `File type ${fileExtention} are not accepted, only (${this.acceptedFileTypes.join(
            ", "
          )})`
        );
        return;
      }

      this.uploading = true;
      var formData = new FormData();
      formData.append("item", this.item);
      formData.append("item_id", this.item_id);
      formData.append("name", fileName);
      formData.append("file", file);

      this.file = formData;
      this.uploadFile();
    },

    uploadFile() {
      this.$store
        .dispatch("apiRequest", {
          method: "POST",
          endpoint: "/file-upload",
          params: this.file,
        })
        .then(() => {
          this.uploading = false;
        });
    },

    dragover(event) {
      event.preventDefault();
      if (!event.currentTarget.classList.contains("upload_hover")) {
        event.currentTarget.classList.add("upload_hover");
      }
    },
    dragleave(event) {
      event.currentTarget.classList.remove("upload_hover");
    },
    drop(event) {
      event.preventDefault();
      this.handleFileChange(event);
      event.currentTarget.classList.remove("upload_hover");
    },
  },
};
</script>

<style scoped>
.upload_container > label {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 300px;
  margin: 5%;
  border: 2px dashed #b9b9b9;
}

.upload_hover,
.upload_container > label:hover {
  border: 2px dashed green;
  background-color: #eeeeee;
  cursor: pointer;
}
.upload_container > input {
  display: none;
}
</style>
