<template>
  <v-container v-if="data">
    <elements-infinite-results :data="data" masonry />
  </v-container>
</template>

<script>
export default {
  created() {
    document.title = "Schdoo";
  },
  computed: {
    data() {
      return { endpoint: this.$route.path };
    },
  },
};
</script>

<style scoped></style>
