<template>
  <v-card class="group-card mb-4" flat>
    <v-card-title
      class="d-flex align-center justify-space-between py-2 flex-nowrap"
    >
      <elements-author-block
        v-if="group.managed_by"
        :representing="group.managed_by"
      />

      <v-spacer></v-spacer>
      <v-chip
        v-if="group.status && !['active'].includes(group.status)"
        label
        color="error"
        small
        class="mx-1"
      >
        {{ group.status }}</v-chip
      >
      <cards-tooltip-icons :items="group.icons" />
    </v-card-title>

    <v-card :to="group.url" flat>
      <v-card-title class="d-flex justify-space-between flex-nowrap pt-0">
        <div>
          <div class="h3">
            {{ group.name }}
          </div>
          <div class="caption">{{ group.member_count }}</div>
        </div>

        <v-avatar v-if="group.avatar" size="60" class="ml-3" color="grey">
          <v-img :src="group.avatar.src_small" :alt="group.avatar.name" />
        </v-avatar>
      </v-card-title>
    </v-card>
  </v-card>
</template>

<script>
export default {
  props: ["group"],
  methods: {},
};
</script>

<style scoped>
.group-card {
  border: 1px solid #b9b9b9;
}
</style>
