<template>
  <v-card
    :to="topic.url"
    flat
    class="d-flex align-center topic-card ma-2"
    :style="topic.status == 'disactive' ? 'opacity:0.4' : 'opacity:1;'"
  >
    <v-img
      v-if="topic.hero"
      :src="topic.hero.src_large"
      :alt="topic.title"
      max-height="50"
      max-width="50"
      contain
    />
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title v-if="topic.label" class="text-h6 text-wrap">
          {{ topic.label }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>

<script>
export default {
  props: ["topic"],
};
</script>

<style lang="scss" scoped>
.topic-card {
  border: 1px solid #b9b9b9;
}
</style>
