<template>
  <div>
    <div v-if="loading" class="text-center pa-4">
      <v-progress-circular
        indeterminate
        class="mx-auto"
        :size="70"
        :width="7"
      />
    </div>
    <v-container v-if="article.title" style="max-width: 750px">
      <div class="d-flex page_header" flat>
        <div class="d-flex align-center avatar-and-label-container">
          <div class="entity_label">
            <div v-if="article.overline" class="text-overline">
              {{ entity.overline }}
            </div>

            <div class="text-h1">{{ article.title }}</div>
            <div class="text-h6">
              {{ article.headline }}
            </div>

            <div class="text-overline">
              {{ article.updated ? "Updated" : "Published" }}:
              <span>{{
                article.updated
                  ? article.updated
                  : article.published | moment("from")
              }}</span>
            </div>

            <v-chip class="mt-2" v-if="article.status" small color="warning">
              {{ article.status }}
            </v-chip>
          </div>
        </div>
        <v-spacer></v-spacer>
        <elements-action-btns :actions="article.actions" />
      </div>

      <div class="py-2"></div>

      <elements-hero-image :hero="article.hero" :name="article.title" />

      <div v-html="article.content" class="ql-editor-custom"></div>
      <vue-editor style="display: none" />
      <div class="py-5"></div>

      <!-- <elements-feedback-btns :data="article.vote_btn" /> -->

      <elements-topics-block :topics="article.topics" class="py-4" />
    </v-container>

    <v-container v-if="article.message">
      <h2>{{ article.message }}</h2>
    </v-container>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";

export default {
  components: { VueEditor },

  data: () => ({
    loading: true,
    article: {},
    defaultTitle: "Schdoo",
    defaultDescription: "Articles on Schdoo",
    defaultImage: "https://schdoo.com/schdoo.png",
  }),
  mounted() {
    this.$store.commit("setLoading", true);
    this.getArticle();
  },
  computed: {
    slug() {
      return this.$route.params.slug;
    },
  },
  watch: {
    slug() {
      this.getArticle();
    },
  },
  methods: {
    getArticle() {
      this.article = {};
      this.loading = true;
      window.scrollTo(0, 0);
      this.$store
        .dispatch("apiRequest", {
          method: "GET",
          endpoint: "articles/" + this.slug,
        })
        .then(() => {
          this.article = this.$store.getters.apiResponse.data;
          if (this.article.title) {
            this.updateMetaTags(this.article);
          } else {
            this.article.message = "Article is unavailable";
          }
          this.loading = false;
        });
    },
    updateMetaTags(article) {
      document.title = article.title
        ? `${article.title} | ` + this.defaultTitle
        : this.defaultTitle;

      const metaTags = [
        { property: "og:url", content: window.location.href },
        { property: "og:type", content: "article" },
        { property: "og:title", content: article.title || this.defaultTitle },
        {
          property: "og:description",
          content: article.headline || this.defaultDescription,
        },
        {
          property: "og:image",
          content: article.hero ? article.hero.src_large : this.defaultImage,
        },
        {
          name: "description",
          content: article.headline || this.defaultDescription,
        },

        { property: "twitter:domain", content: this.defaultTitle },
        { property: "twitter:url", content: window.location.href },
        { name: "twitter:title", content: article.title || this.defaultTitle },
        {
          name: "twitter:description",
          content: article.headline || this.defaultDescription,
        },
        {
          name: "twitter:image",
          content: article.hero ? article.hero.src_large : this.defaultImage,
        },
      ];

      metaTags.forEach((tag) => {
        this.updateMetaTag(tag);
      });
    },
    updateMetaTag(tag) {
      let metaElement = document.querySelector(
        `meta[${tag.property ? "property" : "name"}="${
          tag.property || tag.name
        }"]`
      );
      if (metaElement) {
        metaElement.setAttribute("content", tag.content);
      } else {
        metaElement = document.createElement("meta");
        if (tag.property) {
          metaElement.setAttribute("property", tag.property);
        } else {
          metaElement.setAttribute("name", tag.name);
        }
        metaElement.setAttribute("content", tag.content);
        document.head.appendChild(metaElement);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page_header {
  justify-content: center;
  flex-wrap: wrap;
  padding: 0;
}

.avatar-and-label-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.entity_label {
  text-align: center;
  width: 100%;
}

@media (min-width: 600px) {
  .page_header {
    justify-content: space-between;
  }

  .avatar-and-label-container {
    flex-direction: row;
    align-items: center;
    width: auto;
  }

  .entity_label {
    text-align: left;
    width: auto;
  }
}

.ql-editor-custom {
  margin: 2% auto 5% auto;
  padding: 0 0;
  font-size: 18px;
  line-height: 28px;
  p {
    margin-bottom: 0 !important;
  }
  blockquote {
    font-size: 1.2em;
    width: 90%;
    margin: 0 auto;
    font-style: italic;
    color: #555555;
    padding: 1.2em 30px 1.2em 75px;
    border-left: 8px solid #78c0a8;
    line-height: 1.5;
    position: relative;
    background: #ededed;
  }
  .ql-size-small {
    /* font-size: 0.75em; */
    font-size: 10px;
  }

  .ql-size-large {
    /* font-size: 1.5em; */
    font-size: 14px;
  }

  .ql-size-huge {
    /* font-size: 2.5em; */
    font-size: 16px;
  }
}
</style>
