import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Vuelidate from 'vuelidate'
import VueCookie from 'vue-cookie'
import VueMasonry from 'vue-masonry-css'

import "./components/register.js"

Vue.use(Vuelidate);
Vue.use(require('vue-moment'));
Vue.use(VueCookie);
Vue.use(VueMasonry);
Vue.config.productionTip = false

window.Vue = require('vue').default;

let savedScrollPositions = {};

window.addEventListener('scroll', () => {
  savedScrollPositions[router.currentRoute.fullPath] = window.pageYOffset || document.documentElement.scrollTop;
});

router.beforeEach((to, from, next) => {
  Vue.nextTick(() => {
    const savedScrollPosition = savedScrollPositions[to.fullPath];
    if (savedScrollPosition !== undefined) {
      setTimeout(() => {
        window.scrollTo(0, savedScrollPosition);
      }, 500);
    }
  });

  next();
});


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')


