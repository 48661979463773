<template>
  <v-card flat class="mb-4">
    <v-list-item :to="role.url">
      <v-list-item-content>
        <v-list-item-title v-if="role.label" class="text-h5 text-wrap">
          {{ role.label }}
          <v-chip v-if="role.school_role" x-small>Schools</v-chip>
          <v-chip v-if="role.contributor_role" x-small>Contributors</v-chip>
        </v-list-item-title>
        <small>{{ role.stats }}</small>
      </v-list-item-content>
    </v-list-item>

    <v-card-actions v-if="role.actions" class="pt-0">
      <elements-action-btns :actions="role.actions" />
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ["role"],
};
</script>

<style lang="scss" scoped></style>
