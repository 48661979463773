<template>
  <div class="py-5">
    <div v-if="data.notice" class="text-center mb-6">
      <div class="text-h3">{{ data.notice }}</div>
    </div>

    <div class="d-flex justify-space-between flex-wrap">
      <div>
        <div v-if="data.header" class="text-h2 mr-4">{{ data.header }}</div>

        <div v-if="data.subheader" class="text-overline">
          {{ data.subheader }}
        </div>
      </div>
      <v-spacer></v-spacer>

      <v-row class="filter_container justify-end" v-if="data.filters">
        <v-col v-for="(filter, index) in data.filters" :key="index">
          <v-select
            v-if="filter.type == 'select'"
            v-model="options[filter.name]"
            :items="filter.options"
            :label="filter.label"
            :item-text="filter.display"
            :item-value="filter.value ? filter.value : 'id'"
            :disabled="filter.options.length == 0"
            @input="getDetails()"
            dense
            outlined
            hide-details
            clearable
          >
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>
                  <v-icon v-if="item.disabled" small class="mr-2"
                    >mdi-lock</v-icon
                  >
                  {{ item[filter.display] }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </v-select>
        </v-col>

        <v-col v-if="data.helper">
          <v-btn
            @click="$store.commit('setSideDrawerRequest', data.helper.endpoint)"
            small
            plain
            >{{ data.helper.label }}</v-btn
          >
        </v-col>
        <v-col v-if="data.actions" class="filter_actions">
          <elements-action-btns :actions="data.actions" position="end" />
        </v-col>
      </v-row>
    </div>

    <div v-if="loading" class="text-center">
      <v-progress-circular
        indeterminate
        class="mx-auto"
        :size="70"
        :width="7"
      />
      <div class="text-body-2 pa-4">Loading turnout</div>
    </div>

    <div v-if="data.warning" class="text-h6 my-4 mx-auto">
      {{ data.warning }}
    </div>

    <div v-if="data.results" class="d-flex flex-wrap flex-grow-1 mx-2">
      <div
        v-for="(result, index) in data.results"
        :key="index"
        class="data-result text-center"
      >
        <div class="text-body-2">{{ result.label }}</div>
        <div v-if="result.votes" class="text-body-1 align-center pa-3">
          {{ result.votes }}
        </div>

        <div class="py-1">
          <div
            v-for="(value, key) in result.count"
            :key="result.label + key"
            class="d-flex justify-space-between"
          >
            <div>{{ key }}</div>
            <div>
              <strong>{{ value }}</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["route"],
  data() {
    return {
      loading: false,
      data: [],
      options: {},
    };
  },
  watch: {
    route: {
      handler: function () {
        this.results = false;
        this.getDetails();
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    getDetails() {
      if (!this.route) return;
      this.loading = true;

      let options = Object.fromEntries(
        Object.entries(this.options).filter(([, v]) => v != null)
      );

      this.$store
        .dispatch("apiRequest", {
          method: "GET",
          endpoint: this.route,
          params: options,
        })
        .then(() => {
          this.data = this.$store.getters.apiResponse.data;
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.filter_container {
  margin: 4px;
  padding: 4px;
  .col {
    flex-wrap: wrap;
    min-width: 170px;
    max-width: 200px;
    margin: 0;
    padding: 7px;
  }
  .filter_actions {
    .v-card__actions {
      padding: 0;
    }
  }
}
@media (max-width: 600px) {
  .filter_container {
    margin: 0;
    padding: 0;
    .col {
      min-width: 50%;
    }
  }
}
.data-result {
  border-radius: 15px;
  background-color: #d5e2e1;
  min-width: 280px;
  max-width: 95vw;
  margin: 0 auto 20px auto;
  padding: 10px;
}
</style>
