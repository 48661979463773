<template>
  <div>
    <cards-contributor-template
      v-if="item.entity == 'contributor'"
      :contributor="item"
    />
    <cards-school-template v-else-if="item.entity == 'school'" :school="item" />
    <cards-group-template v-else-if="item.entity == 'group'" :group="item" />

    <cards-student-template
      v-else-if="item.entity == 'student'"
      :student="item"
    />
    <cards-article-template v-else-if="item.entity == 'article'" :data="item" />
    <cards-user-template v-else-if="item.entity == 'user'" :user="item" />
    <cards-topic-template v-else-if="item.entity == 'topic'" :topic="item" />
    <cards-media-template v-else-if="item.entity == 'image'" :media="item" />
    <cards-region-template v-else-if="item.entity == 'region'" :region="item" />
    <cards-role-template v-else-if="item.entity == 'role'" :role="item" />
    <cards-generic-template v-else :data="item" />
  </div>
</template>

<script>
export default {
  props: ["item"],
};
</script>

<style></style>
