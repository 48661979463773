<template>
  <div>
    <div v-if="header || (topics && topics.length > 0)" class="pa-0 ma-0">
      <v-card
        v-if="header"
        :color="header.colour ? header.colour : 'transparent'"
        class="py-3"
        flat
        shaped
      >
        <div class="mb-3 d-flex avatar-and-label-container">
          <v-avatar v-if="hero" size="70" tile class="rounded mr-3">
            <v-img :src="hero.src_small" :alt="hero.name" />
          </v-avatar>
          <div>
            <div class="text-h2" v-html="header"></div>
            <div v-if="description" class="caption" v-html="description"></div>
          </div>
          <v-spacer></v-spacer>
          <elements-action-btns
            v-if="headerActions"
            :actions="headerActions"
            size="small"
            position="end"
          />
        </div>
      </v-card>
    </div>

    <v-row v-if="filters || actions || helper" class="filter_container">
      <v-col v-for="(filter, index) in filters" :key="index">
        <v-text-field
          v-if="filter.type == 'search'"
          v-model="options[filter.name]"
          :label="filter.label"
          dense
          outlined
          clearable
          hide-details
          @input="refresh()"
        />
        <v-select
          v-if="filter.type == 'select'"
          v-model="options[filter.name]"
          :items="filter.options"
          :label="filter.label"
          :item-text="filter.display"
          :item-value="filter.value ? filter.value : 'id'"
          :disabled="filter.options.length == 0"
          @input="refresh()"
          dense
          outlined
          hide-details
          clearable
        ></v-select>
      </v-col>

      <v-spacer></v-spacer>
      <v-col v-if="helper">
        <v-btn
          @click="$store.commit('setSideDrawerRequest', helper.endpoint)"
          small
          plain
          >{{ helper.label }}</v-btn
        >
      </v-col>
      <v-col v-if="actions" class="filter_actions">
        <elements-action-btns :actions="actions" position="end" />
      </v-col>
    </v-row>

    <elements-banner-template :banner="banner" />

    <div v-if="!$router.isScrolling" class="my-4">
      <div
        class="caption my-2 text-right"
        v-if="showCount && totalResults > 20"
      >
        {{ totalResults }} results
      </div>

      <masonry
        v-if="masonry"
        :gutter="{ default: '15px', 800: '10px' }"
        :cols="{ default: 4, 1300: 3, 800: 2, 500: 1 }"
      >
        <cards-select-template
          v-for="(item, index) in results"
          :key="index"
          :item="item"
        />
      </masonry>
      <div v-else>
        <cards-select-template
          v-for="(item, index) in results"
          :key="index"
          :item="item"
        />
      </div>

      <div v-if="loading" class="text-center pa-4">
        <v-progress-circular
          indeterminate
          class="mx-auto"
          :size="70"
          :width="7"
        />
      </div>

      <v-card v-intersect="infiniteScrolling"></v-card>
      <div v-if="!loading && resultCount" class="caption">
        {{ resultCount }}
      </div>
      <div v-if="error" class="caption">{{ error }}</div>
    </div>
    <elements-banner-template v-if="bottomBanner" :banner="bottomBanner" />

    <elements-topics-block :topics="topics" />
  </div>
</template>

<script>
import debounce from "lodash.debounce";
import axios from "axios";

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    masonry: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      options: {
        page: 1,
        itemsPerPage: 40,
      },
      results: [],
      showCount: false,
      totalResults: 0,
      itemsReturned: 0,
      totalPages: 0,
      nextPage: 1,
      settings: [],
      filters: [],
      headerActions: [],
      actions: [],
      topics: [],
      banner: false,
      bottomBanner: false,
      helper: false,
      header: false,
      description: false,
      hero: false,
      error: false,
    };
  },

  watch: {
    data: {
      handler() {
        this.resetOptions();
        this.fetchData();
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    this.debouncedCallback = debounce((nextpage) => {
      if (nextpage === true) {
        this.options.page = this.nextPage;
      }
      this.fetchData();
    }, 500);
  },
  computed: {
    endpoint() {
      if (this.data) return this.data.endpoint;
      return false;
    },
    itemType() {
      return this.data ? this.data.type : null;
    },
    resultCount() {
      if (this.totalResults == 0 && !this.error) {
        return "No results";
      }
      if (!this.itemsReturned && this.showCount) {
        return "Showing all " + this.totalResults + " results";
      }
      if (!this.showCount) return;
      return this.itemsReturned + " / " + this.totalResults + " results";
    },
  },
  methods: {
    refresh() {
      this.nextPage = 1;
      this.results = [];
      this.debouncedCallback(true);
    },
    resetOptions() {
      this.results = [];
      this.settings = [];
      this.options = {
        page: 1,
        itemsPerPage: 40,
      };
    },
    waitForAxios(retryDelay = 100) {
      return new Promise((resolve) => {
        const intervalId = setInterval(() => {
          if (axios) {
            clearInterval(intervalId);
            resolve();
          }
        }, retryDelay);
      });
    },
    async fetchData() {
      if (!this.endpoint) return;
      if (this.loading == true) return;
      this.loading = true;
      await this.waitForAxios();

      let options = Object.fromEntries(
        Object.entries(this.options).filter(([, v]) => v != null)
      );

      const url = this.endpoint == "/" ? "/newsfeed" : this.endpoint;
      const params = new URLSearchParams(options).toString();

      try {
        const response = await axios.get(url + "?" + params);
        const dataResults = response.data;
        if (!dataResults) {
          this.resetOptions();
          return;
        }
        dataResults.data.forEach((item) => this.results.push(item));
        this.totalResults = dataResults.total;
        this.showCount = dataResults.show_count;
        this.itemsReturned = dataResults.to;
        this.totalPages = dataResults.last_page;
        this.nextPage = dataResults.current_page;
        this.nextPage++;
        this.headerActions = dataResults.header_actions;
        this.actions = dataResults.actions;
        this.filters = dataResults.filters;
        this.settings = dataResults.settings;
        this.banner = dataResults.banner;
        this.bottomBanner = dataResults.bottom_banner;
        this.topics = dataResults.topics;
        this.helper = dataResults.helper;
        this.header = dataResults.header;
        this.hero = dataResults.hero;
        this.description = dataResults.description;
      } catch (error) {
        this.results = [];
        if (error.response.status == 401) {
          this.$router.push({
            path: "/login",
            query: { ref: this.$router.history.current.path },
          });
          return;
        }
        if (error.response?.status == 404) {
          this.error = "Not found";
          return;
        }
        if (error.response.data?.error) {
          this.error = error.response.data?.error;
          return;
        }
        this.error = error;
      } finally {
        this.loading = false;
      }
    },
    infiniteScrolling() {
      if (this.totalPages < this.nextPage) return;
      this.debouncedCallback(true);
    },
  },
};
</script>

<style lang="scss" scoped>
.row {
  margin: 0;
}

.filter_container {
  .col {
    flex-wrap: wrap;
    min-width: 170px;
    max-width: 200px;
    margin: 0;
    padding: 7px;
  }
  .filter_actions {
    .v-card__actions {
      padding: 0;
    }
  }
}
@media (max-width: 600px) {
  .filter_container {
    margin: 0;
    padding: 0;
    .col {
      min-width: 50%;
    }
  }
}

.avatar-and-label-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
}

@media (min-width: 600px) {
  .avatar-and-label-container {
    flex-direction: row;
    align-items: center;
    width: auto;
    text-align: left;
  }
}
</style>
