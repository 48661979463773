<template>
  <div>
    <div
      v-for="(value, permission, index) in permissions"
      :key="index"
      class="pa-1"
    >
      <span class="mr-1">
        <v-icon v-if="value" color="green" small>mdi-account-check</v-icon>
        <v-icon v-else color="red" small>mdi-account-cancel</v-icon>
      </span>
      <span :style="value ? '' : 'text-decoration: line-through;'">{{
        strFormat(permission)
      }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: ["permissions"],
  methods: {
    strFormat(str) {
      return str
        .replace(/_/g, " ")
        .toLowerCase()
        .replace(/\b[a-z]/g, function (letter) {
          return letter.toUpperCase();
        });
    },
  },
};
</script>

<style></style>
