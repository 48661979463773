<template>
  <v-app :style="{ background: $vuetify.theme.themes[theme].background }">
    <elements-navigation-bar v-if="!isDown" />
    <v-main>
      <v-progress-linear
        v-if="this.$store.state.isLoading"
        fixed
        indeterminate
      ></v-progress-linear>
      <router-view :key="routeKey" />
    </v-main>
    <elements-footer-section v-if="!isDown" />
    <elements-notifications-popup />
    <elements-dialog-block />
    <elements-side-drawer />
    <elements-media-uploader />
    <elements-cookie-block />
  </v-app>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      routeKey: this.$route.fullPath,
    };
  },
  watch: {
    refreshKey(newVal, oldVal) {
      this.routeKey = `${this.$route.fullPath}-${newVal}`;
    },
  },
  computed: {
    refreshKey() {
      return this.$store.state.refreshRouterViewKey;
    },
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    isDown() {
      return ["/503", "/403"].includes(this.$route.fullPath);
    },
  },
};
</script>

<style lang="scss">
* {
  word-break: keep-all !important;
  white-space: normal !important;
}
.v-main__wrap {
  padding: 0 2% 2% 2%;
}
.v-toolbar__content,
.v-toolbar__extension {
  padding: 0px !important;
}

.v-btn {
  text-transform: unset !important;
  margin: 2px;
  .v-icon {
    margin: 0 5px 0 0;
  }
}

.theme--light.v-tabs > .v-tabs-bar,
.theme--light.v-tabs-items {
  background: transparent !important;
  background-color: transparent !important;
}
.v-tabs-bar.v-tabs-bar {
  text-transform: unset !important;
}
.v-tab {
  font-size: 1.1rem !important;
  font-weight: 300 !important;
  text-transform: unset !important;
  letter-spacing: normal !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
}
.v-slide-group__prev {
  display: none !important;
}
</style>
