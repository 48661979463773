<template>
  <div
    v-if="user"
    class="d-flex align-center user-avatar"
    @click="user.url ? $store.dispatch('redirect', user.url) : false"
  >
    <v-avatar size="25" color="grey">
      <v-img
        v-if="user.avatar"
        :src="user.avatar.src_small"
        :alt="user.avatar.name"
      />
      <span v-else class="white--text caption">{{ initials }}</span>
    </v-avatar>
    <div class="label">
      <span v-if="label">{{ label }}</span>
      {{ user.label }}
    </div>
  </div>
</template>

<script>
export default {
  props: ["user", "label", "sublabel", "sublabelDate"],
  computed: {
    initials() {
      return !this.user.label
        ? null
        : this.user.label
            .split(" ")
            .map((n) => n[0])
            .join("");
    },
  },
};
</script>

<style lang="scss" scoped>
.user-avatar {
  max-width: 250px;
  margin-top: 10px;
  border-radius: 45px;
  .label {
    margin-left: 5px;
    color: #6e6d6d;
    font-size: 0.92rem;
    font-weight: 400;
    line-height: 1rem;
    padding: 5px 10px 5px 2px;
  }
}
.user-avatar:hover {
  cursor: pointer;
  background-color: #000;
  .label {
    color: #fff;
  }
}
</style>
