<template>
  <v-card
    v-if="contributor"
    @click="$router.push(contributor.url)"
    class="mb-3"
    flat
  >
    <v-img
      v-if="contributor.avatar"
      :src="contributor.avatar.src_large"
      :alt="contributor.name"
      width="100%"
      fluid
    />
    <v-chip v-if="contributor.status" label small color="error">{{
      contributor.status
    }}</v-chip>
    <v-card-title min-height="200">
      <div class="align-center">
        <div class="text-h3">{{ contributor.name }}</div>
        <div class="caption">{{ contributor.type }}</div>
        <div v-if="contributor.role" class="caption">
          {{ contributor.role }}
        </div>
      </div>
    </v-card-title>
    <v-card-actions v-if="contributor.actions">
      <elements-action-btns :actions="contributor.actions" />
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ["contributor"],
  methods: {},
};
</script>

<style></style>
