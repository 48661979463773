<template>
  <v-card-actions
    v-if="actions"
    class="flex-wrap action_container"
    :class="position ? 'justify-' + position : 'justify-center'"
  >
    <v-btn
      v-for="(action, index) in actions"
      class="text-wrap"
      :key="index"
      :color="action.colour ? action.colour : 'primary'"
      :disabled="action.action == 'disabled'"
      @click="
        action.warning
          ? openWarning(action)
          : $store.dispatch('processAction', action)
      "
      :plain="btnType == 'plain' ? true : false"
      :outlined="btnType == 'plain' ? false : true"
      :small="size == 'small' ? true : false"
      >{{ action.label }}
    </v-btn>

    <v-dialog v-model="dialogVisible" max-width="500px">
      <v-card class="text-center py-5">
        <v-card-text
          class="pa-3 body-2"
          style="word-break: break-word"
          v-html="activeAction.warning"
        >
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="error" outlined @click="close">Cancel</v-btn>
          <v-btn color="success" outlined @click="confirm">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card-actions>
</template>

<script>
export default {
  props: ["actions", "btnType", "position", "size"],
  data() {
    return {
      dialogVisible: false,
      activeAction: [],
    };
  },
  methods: {
    openWarning(action) {
      this.activeAction = action;
      this.dialogVisible = true;
    },
    close() {
      this.dialogVisible = false;
    },
    confirm() {
      this.$store.dispatch("processAction", this.activeAction);
      this.close();
    },
  },
};
</script>

<style scoped>
@media (max-width: 600px) {
  .action_container {
    justify-content: left !important;
  }
}
.v-btn--plain > span {
  color: #000;
}
.v-btn--disabled {
  pointer-events: visible;
  cursor: not-allowed !important;
}
</style>
